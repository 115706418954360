import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useCountryService } from '$hooks/services'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText as BaseHookFieldText } from '@genie-fintech/ui/components/hook-fields'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { schema, TFormValues } from './constants'
import { getFlagUrl } from '$app/utils'

const descriptionText = `Setup a mobile, web or IoT application to use CARROsso for Authentication.`

interface ICountryFormProps {
  defaultValues: TFormValues
}

const CountryForm = ({ defaultValues }: ICountryFormProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { countryId } = params

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  const { createCountry, creatingCountry, updateCountry, updatingCountry } =
    useCountryService()

  const title = (() => {
    if (countryId) {
      return (
        <article className="inline-flex items-center gap-2">
          <article className="w-7 h-7 rounded-full overflow-hidden border border-[--colors-neutral-10]">
            <img
              src={getFlagUrl(defaultValues.country_code)}
              className="rounded-full w-full h-full object-cover"
            />
          </article>
          <span>{defaultValues.name}</span>
        </article>
      )
    }
    return `Add New Country`
  })()

  const handleOnCancel = useCallback(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const onSubmit = useCallback(
    (payload: TFormValues) => {
      if (countryId) {
        updateCountry(countryId, payload)
        return
      }

      createCountry(payload)
    },
    [countryId, updateCountry, createCountry]
  )

  const isProcessing = creatingCountry || updatingCountry

  return (
    <form
      className="flex flex-col bg-[--colors-area-high] rounded-lg border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]"
      onSubmit={handleSubmit(onSubmit)}
    >
      <header className="flex items-center gap-2 justify-between px-5 py-2 border-b border-[--colors-neutral-10]">
        <p className="text-xl font-semibold text-[--colors-text-light]">
          {title}
        </p>

        <article className="flex items-center gap-x-2">
          {countryId && (
            <SaveWithShortCutButton
              disabled={!isDirty || isProcessing}
              loading={isProcessing}
            />
          )}

          {!countryId && (
            <>
              <Button
                disabled={isProcessing}
                styleVariants={{ type: 'outlined', kind: 'neutral' }}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={!isDirty || isProcessing}
                type="submit"
                className="!px-4"
              >
                {isProcessing && <Spinner />}
                Add
              </Button>
            </>
          )}
        </article>
      </header>

      <main className="grid lg:grid-cols-[40%_60%] p-5 gap-2">
        <article className="flex flex-col gap-1">
          <p className="font-semibold text-[--colors-text-neutral]">
            COUNTRY INFO
          </p>
          <p className="text-[--colors-neutral-50] text-xs">
            {descriptionText}
          </p>
        </article>

        <article className="flex flex-col gap-4 px-5 max-w-[500px]">
          <BaseHookFieldText
            control={control}
            name="name"
            label="Country Name"
            required
          />

          <BaseHookFieldText
            control={control}
            name="country_code"
            label="Country Code"
            required
          />

          <BaseHookFieldText
            control={control}
            name="phone_code"
            label="Phone Code"
            required
          />

          <BaseHookFieldText
            control={control}
            name="timezone"
            label="Timezone"
            required
          />

          <BaseHookFieldText
            control={control}
            name="currency_name"
            label="Currency Name"
            required
          />

          <BaseHookFieldText
            control={control}
            name="currency_code"
            label="Currency Code"
            required
          />

          <BaseHookFieldText
            control={control}
            name="currency_symbol"
            label="Currency Symbol"
            required
          />
        </article>
      </main>
    </form>
  )
}

export default CountryForm
