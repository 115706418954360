import { ROUTE_NAMES } from '$router/config'

export type TCategory = 'application' | 'group' | 'role' | 'user' | 'permission'

type TData = {
  title?: string
  primaryBtnText?: string
  redirectPath: ROUTE_NAMES
}

export const CategoryData: Record<TCategory, TData> = {
  application: {
    title: 'DELETE APPLICATION SETTING',
    primaryBtnText: 'Delete Application',
    redirectPath: ROUTE_NAMES.APPS
  },
  group: {
    title: 'DELETE GROUP SETTING',
    primaryBtnText: 'Delete Group',
    redirectPath: ROUTE_NAMES.APP_GROUPS
  },
  role: {
    title: 'ROLE TERMINATION',
    primaryBtnText: 'Terminate Role',
    redirectPath: ROUTE_NAMES.APP_ROLES
  },
  user: {
    title: 'USER TERMINATION',
    primaryBtnText: 'Terminate User',
    redirectPath: ROUTE_NAMES.APP_USERS
  },
  permission: {
    title: 'PERMISSION TERMINATION',
    primaryBtnText: 'Terminate Permission',
    redirectPath: ROUTE_NAMES.APP_PERMISSIONS
  }
}
