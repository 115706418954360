import { getAppLaunchpads, putAppLaunchpads } from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppLaunchpadService = () => {
  const {
    data: appLaunchpadData,
    run: fetchAppLaunchpads,
    runAsync: fetchAppLaunchpadsAsync,
    loading: fetchingAppLaunchpads
  } = useRequest(getAppLaunchpads, {
    manual: true
  })

  const { launchpads = [] } = appLaunchpadData?.data ?? {}

  const {
    run: updateAppLuanchpads,
    runAsync: updateAppLuanchpadsAsync,
    loading: updatingAppLuanchpads
  } = useRequest(putAppLaunchpads, {
    manual: true,
    onSuccess: (_, [appId]) => {
      toast.success('Successfully Updated!'),
        fetchAppLaunchpads({ application_id: appId })
    }
  })

  return {
    launchpads,
    fetchAppLaunchpads,
    fetchAppLaunchpadsAsync,
    fetchingAppLaunchpads,

    updateAppLuanchpads,
    updateAppLuanchpadsAsync,
    updatingAppLuanchpads
  }
}
