import CSVImport from '$blocks/CSVImport'

const AppUserCSVImport = () => {
  return (
    <article className="flex py-5">
      <CSVImport type="user" />
    </article>
  )
}

export default AppUserCSVImport
