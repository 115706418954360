import AppPermissionForm from '$blocks/AppPermission/Form'
import {
  DEFAULT_FORM_VALUES,
  TFormValues
} from '$blocks/AppPermission/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppPermissionService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import { useMemo } from 'react'

const AppPermissionEdit = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const {
    route: { params }
  } = useRouteSummary()

  const { appId, permissionId } = params

  const { permission, fetchAppPermissionDetail, fetchingAppPermissionDetail } =
    useAppPermissionService()

  useMount(() => {
    if (!appId || !permissionId) return
    fetchAppPermissionDetail({
      application_id: appId,
      permission_id: permissionId
    })
  })

  const defaultValues = useMemo(() => {
    if (!permission) return DEFAULT_FORM_VALUES

    const { roles, ...rest } = permission

    const modifiedData: TFormValues = {
      roles: roles.map(v => v.id),
      ...rest
    }

    return modifiedData
  }, [permission])

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[
          { name: `${appName} Permissions`, path: ROUTE_NAMES.APP_PERMISSIONS },
          { name: `${permission?.name ?? ''}` }
        ]}
      />

      {fetchingAppPermissionDetail && <Loading />}

      {!fetchingAppPermissionDetail && (
        <AppPermissionForm
          appName={appName}
          roles={permission?.roles ?? []}
          defaultValues={defaultValues}
        />
      )}
    </>
  )
}

export default AppPermissionEdit
