import AppGroupForm from '$app/blocks/AppGroup/Form'
import { DEFAULT_FORM_VALUES } from '$blocks/AppGroup/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'

const AppGroupCreate = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[
          { name: `${appName} Groups`, path: ROUTE_NAMES.APP_GROUPS },
          { name: `Add New Group` }
        ]}
      />

      <AppGroupForm appName={appName} defaultValues={DEFAULT_FORM_VALUES} />
    </>
  )
}

export default AppGroupCreate
