export const Logo = () => {
  return (
    <svg
      width="130"
      height="24"
      viewBox="0 0 130 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_208_5392)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5212 7.60822C16.5456 4.78745 14.2484 3.6369 10.5924 3.6369C7.78774 3.6369 5.14868 4.69693 3.16142 6.62171C1.12275 8.59626 0 11.2878 0 14.2007C0 19.7873 4.13554 24 9.61956 24C13.034 24 15.5776 22.9156 17.6248 20.5871C17.7094 20.4911 17.7504 20.3629 17.7375 20.2358C17.7245 20.1083 17.6584 19.9907 17.5558 19.9126L14.8506 17.8601C14.6512 17.7083 14.3679 17.7397 14.2059 17.9314C13.0934 19.2485 11.5986 19.916 9.76262 19.916C6.84612 19.916 4.72943 17.5242 4.72943 14.2291C4.72943 12.4497 5.29948 10.8091 6.33444 9.61004C7.38598 8.39179 8.85754 7.7211 10.478 7.7211C12.3603 7.7211 13.7723 8.47573 14.7949 10.0282C14.8628 10.1314 14.9669 10.2026 15.0879 10.2288C15.2113 10.2553 15.3374 10.2329 15.4429 10.1646L18.3916 8.26345C18.4972 8.19544 18.5717 8.08593 18.596 7.9631C18.6203 7.84011 18.5931 7.71084 18.5212 7.60822Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.0288 13.4489C49.6633 13.4489 51.7693 11.8405 51.7693 8.28104C51.7693 5.23504 49.9008 4.07142 46.6402 4.07142H46.0832C45.9731 4.07142 45.8788 4.1513 45.8599 4.26061L44.3092 13.1809C44.2849 13.3207 44.3917 13.4489 44.5326 13.4489H46.0288ZM54.5852 23.2721H50.207C50.123 23.2721 50.0459 23.2253 50.0065 23.1505L47.0831 17.5865C47.0408 17.506 46.9554 17.4604 46.8654 17.467C46.5744 17.4882 46.2799 17.4882 45.9606 17.4882H43.7731C43.6631 17.4882 43.569 17.5678 43.5498 17.6769L42.5971 23.0835C42.5778 23.1925 42.4837 23.2721 42.3737 23.2721H38.1806C38.0396 23.2721 37.9326 23.1438 37.9571 23.0038L41.9531 0.188879C41.9723 0.0795763 42.0664 0 42.1766 0H47.1153C53.0598 0 56.5583 3.04555 56.5583 8.28204C56.5583 11.6291 55.0315 14.8125 51.5326 16.1997C51.4065 16.2496 51.3489 16.3964 51.4123 16.517L54.7857 22.9366C54.8657 23.0889 54.7562 23.2721 54.5852 23.2721Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.5355 9.74085C69.5355 12.6727 67.786 14.1591 64.3354 14.1591H62.9301C62.9236 14.1591 62.9184 14.1569 62.9141 14.152C62.91 14.1473 62.9085 14.1418 62.9097 14.1357L64.3663 6.19242C64.368 6.1829 64.3767 6.17605 64.3868 6.17605H64.9098C68.0657 6.17605 69.5355 7.30873 69.5355 9.74085ZM69.5625 16.9875C69.5608 16.9843 69.5594 16.9808 69.5616 16.9751C69.563 16.9716 69.5667 16.9652 69.5761 16.9616C72.6538 15.805 74.4189 13.1728 74.4189 9.74036C74.4189 7.40032 73.5864 5.47266 72.0115 4.16578C70.4473 2.86798 68.1461 2.18188 65.3564 2.18188H60.7173C60.5205 2.18188 60.3526 2.31637 60.3181 2.50172L56.5645 22.8178C56.5438 22.931 56.5762 23.0461 56.6532 23.1339C56.7305 23.2218 56.8436 23.2722 56.9637 23.2722H60.9025C61.0994 23.2722 61.2673 23.1381 61.3015 22.9527L62.1963 18.1383C62.1982 18.1289 62.2067 18.1219 62.2169 18.1219H64.2718C64.5718 18.1219 64.8554 18.1219 65.1356 18.1024C65.1391 18.1023 65.1511 18.1021 65.1563 18.1117L67.9024 23.0664C67.973 23.1934 68.11 23.2722 68.2606 23.2722H72.3731C72.5166 23.2722 72.6463 23.2022 72.7203 23.0848C72.7944 22.9674 72.7985 22.8251 72.7313 22.704L69.5625 16.9875Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.9025 7.72635C89.0906 7.72635 91.2327 10.0101 91.2327 13.4091C91.2327 17.36 88.8885 19.9128 85.2605 19.9128C82.0721 19.9128 79.9298 17.6289 79.9298 14.23C79.9298 10.279 82.2741 7.72635 85.9025 7.72635ZM93.123 6.40879C91.2889 4.62121 88.7659 3.6369 86.0187 3.6369C83.0938 3.6369 80.3868 4.64407 78.3967 6.47289C76.2811 8.41711 75.1628 11.0696 75.1628 14.1441C75.1628 16.9061 76.1794 19.4217 78.0247 21.2278C79.8513 23.0154 82.3692 24 85.1144 24C88.0413 24 90.7534 22.993 92.751 21.1643C94.8766 19.2186 96.0001 16.5661 96.0001 13.4933C96.0001 10.733 94.9783 8.21691 93.123 6.40879Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0856 17.2276L29.7194 9.60923L30.9445 17.2276H26.0856ZM37.1974 22.8411L32.1996 2.46677C32.1587 2.29986 32.0036 2.18188 31.8251 2.18188H29.4901C29.3476 2.18188 29.2168 2.25746 29.1501 2.37841L17.912 22.7529C17.8457 22.8584 17.8437 22.9908 17.9067 23.0982C17.9697 23.2056 18.0888 23.2722 18.2172 23.2722H22.1369C22.2627 23.2722 22.3789 23.2087 22.4432 23.105L23.8172 20.8865H31.9215L32.482 23.0137C32.5221 23.1657 32.6646 23.2722 32.8278 23.2722H36.838C36.9361 23.2722 37.0343 23.2404 37.1037 23.1736C37.1963 23.0842 37.2305 22.9595 37.1974 22.8411Z"
          fill="currentColor"
        />
        <path
          d="M130 17.1311C130 18.5308 129.747 19.7404 129.242 20.7599C128.736 21.7795 128.043 22.5657 127.162 23.1187C126.28 23.6717 125.274 23.9481 124.142 23.9481C123.01 23.9481 122.004 23.6717 121.122 23.1187C120.241 22.5657 119.548 21.7795 119.042 20.7599C118.537 19.7404 118.284 18.5308 118.284 17.1311C118.284 15.7314 118.537 14.5217 119.042 13.5022C119.548 12.4827 120.241 11.6964 121.122 11.1434C122.004 10.5905 123.01 10.314 124.142 10.314C125.274 10.314 126.28 10.5905 127.162 11.1434C128.043 11.6964 128.736 12.4827 129.242 13.5022C129.747 14.5217 130 15.7314 130 17.1311ZM128.445 17.1311C128.445 15.9819 128.253 15.0121 127.868 14.2215C127.488 13.4309 126.972 12.8326 126.319 12.4265C125.671 12.0204 124.945 11.8174 124.142 11.8174C123.338 11.8174 122.61 12.0204 121.958 12.4265C121.31 12.8326 120.794 13.4309 120.409 14.2215C120.029 15.0121 119.839 15.9819 119.839 17.1311C119.839 18.2802 120.029 19.2501 120.409 20.0406C120.794 20.8312 121.31 21.4295 121.958 21.8356C122.61 22.2417 123.338 22.4448 124.142 22.4448C124.945 22.4448 125.671 22.2417 126.319 21.8356C126.972 21.4295 127.488 20.8312 127.868 20.0406C128.253 19.2501 128.445 18.2802 128.445 17.1311Z"
          fill="currentColor"
        />
        <path
          d="M115.944 13.8133C115.866 13.1566 115.551 12.6468 114.998 12.2839C114.445 11.9211 113.767 11.7396 112.963 11.7396C112.376 11.7396 111.861 11.8347 111.421 12.0247C110.984 12.2148 110.643 12.4762 110.397 12.8088C110.155 13.1415 110.034 13.5195 110.034 13.9429C110.034 14.2971 110.118 14.6017 110.287 14.8566C110.46 15.1071 110.68 15.3166 110.948 15.4851C111.216 15.6493 111.496 15.7854 111.79 15.8934C112.084 15.9971 112.354 16.0813 112.6 16.1461L113.948 16.509C114.294 16.5997 114.678 16.725 115.102 16.8848C115.529 17.0447 115.937 17.2628 116.326 17.5393C116.719 17.8115 117.043 18.1614 117.298 18.5891C117.553 19.0168 117.681 19.5417 117.681 20.1638C117.681 20.8809 117.493 21.5289 117.117 22.1078C116.745 22.6867 116.201 23.1468 115.484 23.4881C114.771 23.8293 113.905 24 112.885 24C111.935 24 111.112 23.8466 110.416 23.5399C109.725 23.2332 109.181 22.8055 108.783 22.2568C108.39 21.7082 108.168 21.071 108.116 20.3452H109.775C109.818 20.8463 109.987 21.2611 110.28 21.5894C110.578 21.9134 110.954 22.1553 111.408 22.3152C111.866 22.4707 112.358 22.5484 112.885 22.5484C113.499 22.5484 114.05 22.4491 114.538 22.2504C115.026 22.0473 115.413 21.7665 115.698 21.4079C115.983 21.0451 116.125 20.6217 116.125 20.1378C116.125 19.6972 116.002 19.3386 115.756 19.0621C115.51 18.7857 115.186 18.561 114.784 18.3882C114.382 18.2154 113.948 18.0642 113.481 17.9346L111.849 17.468C110.812 17.17 109.991 16.7444 109.386 16.1915C108.781 15.6385 108.479 14.9149 108.479 14.0206C108.479 13.2776 108.68 12.6296 109.082 12.0766C109.488 11.5193 110.032 11.0873 110.714 10.7806C111.401 10.4695 112.168 10.314 113.015 10.314C113.87 10.314 114.631 10.4674 115.296 10.7741C115.961 11.0765 116.488 11.4912 116.877 12.0183C117.27 12.5453 117.478 13.1436 117.499 13.8133H115.944Z"
          fill="currentColor"
        />
        <path
          d="M105.828 13.8133C105.75 13.1566 105.435 12.6468 104.882 12.2839C104.329 11.9211 103.651 11.7396 102.847 11.7396C102.26 11.7396 101.746 11.8347 101.305 12.0247C100.869 12.2148 100.527 12.4762 100.281 12.8088C100.039 13.1415 99.9181 13.5195 99.9181 13.9429C99.9181 14.2971 100.002 14.6017 100.171 14.8566C100.344 15.1071 100.564 15.3166 100.832 15.4851C101.1 15.6493 101.38 15.7854 101.674 15.8934C101.968 15.9971 102.238 16.0813 102.484 16.1461L103.832 16.509C104.178 16.5997 104.562 16.725 104.986 16.8848C105.413 17.0447 105.821 17.2628 106.21 17.5393C106.603 17.8115 106.927 18.1614 107.182 18.5891C107.437 19.0168 107.565 19.5417 107.565 20.1638C107.565 20.8809 107.377 21.5289 107.001 22.1078C106.629 22.6867 106.085 23.1468 105.368 23.4881C104.655 23.8293 103.789 24 102.769 24C101.819 24 100.996 23.8466 100.3 23.5399C99.6092 23.2332 99.0649 22.8055 98.6675 22.2568C98.2743 21.7082 98.0518 21.071 98 20.3452H99.6589C99.7021 20.8463 99.8706 21.2611 100.164 21.5894C100.462 21.9134 100.838 22.1553 101.292 22.3152C101.75 22.4707 102.242 22.5484 102.769 22.5484C103.383 22.5484 103.934 22.4491 104.422 22.2504C104.91 22.0473 105.297 21.7665 105.582 21.4079C105.867 21.0451 106.009 20.6217 106.009 20.1378C106.009 19.6972 105.886 19.3386 105.64 19.0621C105.394 18.7857 105.07 18.561 104.668 18.3882C104.266 18.2154 103.832 18.0642 103.366 17.9346L101.733 17.468C100.696 17.17 99.8749 16.7444 99.2701 16.1915C98.6653 15.6385 98.3629 14.9149 98.3629 14.0206C98.3629 13.2776 98.5638 12.6296 98.9655 12.0766C99.3716 11.5193 99.916 11.0873 100.599 10.7806C101.285 10.4695 102.052 10.314 102.899 10.314C103.754 10.314 104.515 10.4674 105.18 10.7741C105.845 11.0765 106.372 11.4912 106.761 12.0183C107.154 12.5453 107.362 13.1436 107.383 13.8133H105.828Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default Logo
