import { useCountryStore } from '$hooks/stores'
import { redirect, ROUTE_NAMES } from '$router/config'
import { getCountries, postCountry, putCountry } from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useCountryService = () => {
  const updateCountries = useCountryStore(state => state.updateCountries)

  const {
    run: fetchCountries,
    runAsync: fetchCountriesAsync,
    loading: fetchingCountries
  } = useRequest(getCountries, {
    manual: true,
    onSuccess: ({ data }) => {
      updateCountries(data)
    }
  })

  const { run: createCountry, loading: creatingCountry } = useRequest(
    postCountry,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Created!')
        fetchCountriesAsync().then(() => {
          redirect(ROUTE_NAMES.COUNTRIES)
        })
      }
    }
  )

  const { run: updateCountry, loading: updatingCountry } = useRequest(
    putCountry,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Updated!')
        fetchCountriesAsync().then(() => {
          redirect(ROUTE_NAMES.COUNTRIES)
        })
      }
    }
  )

  return {
    fetchCountries,
    fetchCountriesAsync,
    fetchingCountries,

    createCountry,
    creatingCountry,

    updateCountry,
    updatingCountry
  }
}
