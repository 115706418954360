import ModKey from '$components/ModKey'
import {
  SaveKey,
  useSaveEventListener
} from '$hooks/actions/useSaveEventListener'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { useRef } from 'react'

const SaveWithShortCutButton = ({
  disabled,
  loading
}: {
  disabled?: boolean
  loading?: boolean
}) => {
  const saveButtonRef = useRef<HTMLButtonElement>(null)

  useSaveEventListener(() => {
    saveButtonRef.current?.click()
  })

  return (
    <article className="flex items-center gap-x-2">
      <p className="text-xs text-[--colors-neutral-50]">Save Changes</p>

      <article className="inline-flex gap-x-1 items-center border py-0.5 px-3 rounded bg-[--colors-alphaNeutral-1] font-medium text-xs text-[--colors-neutral-60]">
        <ModKey />
        <span>+</span>
        <span className="capitalize">{SaveKey}</span>
      </article>

      <Button buttonRef={saveButtonRef} type="submit" disabled={disabled}>
        {loading && <Spinner />}
        Save Changes
      </Button>
    </article>
  )
}

export default SaveWithShortCutButton
