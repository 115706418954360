import { cn } from '$app/utils'

const PublishUnpublishBadge = ({ is_published }: { is_published: boolean }) => {
  return (
    <article>
      <span
        className={cn(
          'text-xs font-medium inline-flex items-center gap-x-1 px-1.5 py-px rounded',
          'text-[--colors-neutral-70] bg-[--colors-alphaNeutral-1]'
        )}
      >
        {is_published ? 'PUBLISHED' : 'UNPUBLISHED'}
      </span>
    </article>
  )
}

export default PublishUnpublishBadge
