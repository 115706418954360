import Axios, { AxiosError, AxiosResponse } from 'axios'
import { APP_API, APP_API_VERSION, APP_CLIENT_ID } from './environments'

export const Api = Axios.create({
  baseURL: `${APP_API}/api`,
  headers: {
    Accept: 'application/json',
    'X-API-TOKEN': APP_CLIENT_ID
  }
})

export const updateAxiosHeaderToken = (token?: string | null) => {
  if (token?.trim().length) {
    Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
  } else {
    delete Api.defaults.headers.common['Authorization']
  }
}

/** ********************************************************************
 * Resolvers
 */
export const successResolver = <T extends AxiosResponse>({
  data
}: T): T['data'] => data

export const errorMessageResolver = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: any,
  defaultMessage = 'Something went wrong!'
): string => {
  return (
    error?.response?.data?.message || error?.response?.message || defaultMessage
  )
}

export const errorStatusResolver = (error: unknown) => {
  return (error as Required<AxiosError>).response.status
}

/** ********************************************************************
 * Common
 */
export type TID = string | number

export type APIWithSearch<T = object> = { q?: string } & T

export type APIWithPager<T = object> = {
  per_page: number
  page: number
} & T

export type APIWithPagerMeta<T = object> = T & {
  meta: {
    total: number
    per_page: number
    current_page: number
    last_page: number
  }
}

export type TAppLogo = {
  key?: string
  url?: string
} | null

/** ********************************************************************
 * Services
 */

/** ********************************************************************
 * FILE UPLOAD
 */
export const configUpload = async () => {
  return Api.post('/dashboard/vapor/signed-storage-url').then(successResolver)
}

/** ********************************************************************
 * LOGIN
 */
export type TLoginPayload = {
  email: string
  password: string
}

export type TLoginResponse = {
  token: string
  data: {
    name: string
  }
}

export const postLogin = async (payload: TLoginPayload) => {
  return Api.post<TLoginResponse>('/dashboard/login', payload).then(
    successResolver
  )
}

/** ********************************************************************
 * LOGOUT
 */
export const postLogout = async () => {
  return Api.post('/dashboard/logout').then(successResolver)
}

/** ********************************************************************
 * Profile
 */
export type TUserProfile = {
  id: string
  name: string
  email: string
  two_factor_enabled: boolean
  role: string
  has_global_access: boolean
}

export const getProfile = async () => {
  return Api.get<AxiosResponse<TUserProfile>>(
    `/${APP_API_VERSION}/profile/info`
  ).then(successResolver)
}

/** ********************************************************************
 * COUNTRY
 */
export type TCountry = {
  id: number
  name: string
  country_code: string
  phone_code: string
  timezone: string
  currency_name: string
  currency_code: string
  currency_symbol: string
}

export const getCountries = async () => {
  return Api.get<AxiosResponse<TCountry[]>>(
    `/${APP_API_VERSION}/countries`
  ).then(successResolver)
}

export type TCountryPayload = Omit<TCountry, 'id'>

export const postCountry = async (payload: TCountryPayload) => {
  return Api.post(`${APP_API_VERSION}/countries`, payload).then(successResolver)
}

export const putCountry = async (id: TID, payload: TCountryPayload) => {
  return Api.put(`${APP_API_VERSION}/countries/${id}`, payload).then(
    successResolver
  )
}
/** ********************************************************************
 * SCOPE
 */
export type TAppScope = {
  id: number
  value: string
}

export const getAppScope = async () => {
  return Api.get<AxiosResponse<TAppScope[]>>(`/${APP_API_VERSION}/scopes`).then(
    successResolver
  )
}

/** ********************************************************************
 * DELETE
 */
export type TDeletePayload = {
  url: string
}

export const deleteByAppId = async ({ url }: TDeletePayload) => {
  const basePath = `/${APP_API_VERSION}`
  const path = basePath.concat(url)
  return Api.delete(path).then(successResolver)
}

/** ********************************************************************
 * IMPORT
 */
export type TImportPayload = {
  appId: TID
  type: 'role' | 'permission' | 'user'
  file: string
}

export const importDataApi = async ({
  appId,
  type,
  ...payload
}: TImportPayload) => {
  return Api.post(`/${APP_API_VERSION}/${appId}/import/${type}s`, payload).then(
    successResolver
  )
}

/** ********************************************************************
 * APPLICATIONS
 */

// APP LIST API
export type TAppListRequest = APIWithPager<APIWithSearch>

export type TAppListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    client_id: string
    environment: {
      key: number
      value: string
    }
    logo: TAppLogo
    brand: string
    group_count: number
    role_count: number
    user_count: number
    is_published: boolean
  }[]
}>

export const getAppList = async (params: TAppListRequest) => {
  return Api.get<TAppListResponse>(`/${APP_API_VERSION}/applications`, {
    params
  }).then(successResolver)
}

// APP SELECT LIST API
export type TAppSelectListRequest = APIWithSearch<{ application_id: TID }> &
  Pick<APIWithPager, 'per_page'>

export type TAppSelectListResponse = {
  data: {
    id: number
    name: string
    logo: TAppLogo
    brand: string
    is_published: boolean
    environment: {
      key: number
      value: string
    }
  }[]
}

export const getAppSelectList = async (params: TAppSelectListRequest) => {
  return Api.get<TAppSelectListResponse>(
    `/${APP_API_VERSION}/applications-select`,
    {
      params
    }
  ).then(successResolver)
}

// PUBLISH/UNPUBLISH APP API
export const getPublishApp = async ({
  application_id
}: {
  application_id: TID
}) => {
  return Api.get(
    `/${APP_API_VERSION}/applications/${application_id}/publish`
  ).then(successResolver)
}

export const getUnPublishApp = async ({
  application_id
}: {
  application_id: TID
}) => {
  return Api.get(
    `/${APP_API_VERSION}/applications/${application_id}/unpublish`
  ).then(successResolver)
}

// CREATE APP API
export type TAppCreatePayload = {
  name: string
  logo: TAppLogo
  brand: string
}

export const postApp = async (payload: TAppCreatePayload) => {
  return Api.post(`/${APP_API_VERSION}/applications`, payload).then(
    successResolver
  )
}

/** ********************************************************************
 * APPLICATION HOME
 */
// GET BASIC SETTING API
export type TAppHomeParams = {
  application_id: TID
  date?: string
  range?: number
}

export type TAppHome = {
  user_count: number
  role_count: number
  group_count: number
  today_active_user_count: number
  recent_active_users: Array<
    {
      browser: string
      created_at: string
      device: string
      id: string
      location: string
      platform: string
      user_email: string
    }[]
  >
  active_users: {
    date: string
    count: number
  }[]
}

export const getAppHome = async ({
  application_id,
  ...params
}: TAppHomeParams) => {
  return Api.get<AxiosResponse<TAppHome>>(
    `/${APP_API_VERSION}/applications/${application_id}/home`,
    { params }
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION BASIC SETTING
 */

// GET BASIC SETTING API
export type TAppBasicSettingParams = {
  application_id: TID
}

export type TAppBasicSetting = {
  id: number
  name: string
  client_id: string
  description: string
  logo: TAppLogo
  brand: string
  scope: {
    id: number
    value: string
  } | null
  api_tokens: {
    api_token: string
    encryption_key: string
  }[]
  login_behavior: string
  is_published: boolean
  environment: {
    key: number
    value: string
  }
}

export const getAppBasicSetting = async (params: TAppBasicSettingParams) => {
  return Api.get<AxiosResponse<TAppBasicSetting>>(
    `/${APP_API_VERSION}/applications/${params.application_id}/basic-info`
  ).then(successResolver)
}

// PUT BASIC SETTING API
export type TAppBasicSettingPayload = {
  name: string
  description: string
  scope_id: number
  login_behavior: string
  logo: TAppLogo
  brand: string
  environment: number
}

export const putAppBasicSetting = async (
  appId: TID,
  payload: TAppBasicSettingPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/basic-info`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION ADVANCED SETTING
 */

// GET ADVANCED SETTING API
export type TAppAdvancedSettingParams = {
  application_id: TID
}

export type TAppAdvancedSetting = {
  acl_type: string
  sso_enabled: boolean
  allowed_multi_device_login: boolean
  callbacks: {
    callback_url: string
  }[]
  allowed_origins: {
    resource: string
  }[]
}

export const getAppAdvancedSetting = async (
  params: TAppAdvancedSettingParams
) => {
  return Api.get<AxiosResponse<TAppAdvancedSetting>>(
    `/${APP_API_VERSION}/applications/${params.application_id}/advanced-info`
  ).then(successResolver)
}

// PUT ADVANCED SETTING API
export type TAppAdvancedSettingPayload = TAppAdvancedSetting

export const putAppAdvancedSetting = async (
  appId: TID,
  payload: TAppAdvancedSettingPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/advanced-info`,
    payload
  ).then(successResolver)
}
/** ********************************************************************
 * APPLICATION LAUNCHPAD SETTING
 */

// GET LAUNCHPAD API
export type TAppLaunchpadParams = {
  application_id: TID
}

export type TAppLaunchpad = {
  name: string
  url: string
  description: string
}

export const getAppLaunchpads = async (params: TAppAdvancedSettingParams) => {
  return Api.get<AxiosResponse<{ launchpads: TAppLaunchpad[] }>>(
    `/${APP_API_VERSION}/applications/${params.application_id}/launchpads`
  ).then(successResolver)
}

// PUT LAUNCHPAD API
export const putAppLaunchpads = async (
  appId: TID,
  launchpads: TAppLaunchpad[]
) => {
  return Api.put(`/${APP_API_VERSION}/applications/${appId}/launchpads`, {
    launchpads
  }).then(successResolver)
}
/** ********************************************************************
 * APPLICATION TOKEN SETTING
 */

// GET TOKEN SETTING API
export type TAppTokenSettingParams = {
  application_id: TID
}

export type TAppTokenSetting = {
  access_token_lifespan: number | null
  refresh_token_expiration: boolean
  refresh_token_lifespan: number | null
  refresh_token_inactive_expiration: boolean
  refresh_token_inactive_lifespan: number | null
  id_token_lifespan: number | null
}

export const getAppTokenSetting = async (params: TAppTokenSettingParams) => {
  return Api.get<AxiosResponse<TAppTokenSetting>>(
    `/${APP_API_VERSION}/applications/${params.application_id}/token-setting`
  ).then(successResolver)
}

// PUT TOKEN SETTING API
export type TAppTokenSettingPayload = TAppTokenSetting

export const putAppTokenSetting = async (
  appId: TID,
  payload: TAppTokenSettingPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/token-setting`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION GROUP
 */

// APP GROUP LIST API
export type TAppGroupListRequest = APIWithPager<
  APIWithSearch<{ application_id: TID }>
>

export type TAppGroupListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    description: string
    role_count: number
    user_count: number
    created_at: string
    country: string
  }[]
}>

// GET GROUP LIST API
export const getAppGroupList = async ({
  application_id,
  ...params
}: TAppGroupListRequest) => {
  return Api.get<TAppGroupListResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/groups`,
    {
      params
    }
  ).then(successResolver)
}

// GET GROUP SELECT LIST API
export type TAppGroupSelectListRequest = APIWithSearch<{
  application_id: TID
  country_id?: TID
}>

export const getAppGroupSelectList = async ({
  application_id,
  ...params
}: TAppGroupSelectListRequest) => {
  return Api.get<AxiosResponse<{ id: number; name: string }[]>>(
    `/${APP_API_VERSION}/applications/${application_id}/groups-select`,
    {
      params
    }
  ).then(successResolver)
}

// GET GROUP DETAIL API
export type TAppGroupDetailRequest = {
  application_id: TID
  group_id: TID
}

export type TAppGroupDetail = {
  id: number
  name: string
  phone_no: string
  phone_code: string
  address: string
  description: string
  country: TCountry
}

export const getAppGroupDetail = async ({
  application_id,
  group_id
}: TAppGroupDetailRequest) => {
  return Api.get<AxiosResponse<TAppGroupDetail>>(
    `/${APP_API_VERSION}/applications/${application_id}/groups/${group_id}`
  ).then(successResolver)
}

export type TAppGroupPayload = {
  name: string
  phone_no?: string
  phone_code?: string
  address?: string
  description?: string
  country_id: number
}

// CREATE GROUP API
export const postAppGroup = async (appId: TID, payload: TAppGroupPayload) => {
  return Api.post(
    `/${APP_API_VERSION}/applications/${appId}/groups`,
    payload
  ).then(successResolver)
}

// UPDATE GROUP API
export const putAppGroup = async (
  appId: TID,
  groupId: TID,
  payload: TAppGroupPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/groups/${groupId}`,
    payload
  ).then(successResolver)
}

// GET GROUP USER API
export type TAppGroupUserRequest = APIWithPager<
  APIWithSearch<{
    application_id: TID
    group_id: TID
  }>
>

export type TAppGroupUserResponse = APIWithPagerMeta<{
  data: {
    id: string
    name: string
    email: string
    status: 'active' | 'inactive'
    role_count: number
  }[]
}>

export const getAppGroupUser = async ({
  application_id,
  group_id,
  ...params
}: TAppGroupUserRequest) => {
  return Api.get<TAppGroupUserResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/groups/${group_id}/users`,
    {
      params
    }
  ).then(successResolver)
}

// GET GROUP ROLE API
export type TAppGroupRoleRequest = APIWithPager<
  APIWithSearch<{
    application_id: TID
    group_id: TID
  }>
>

export type TAppGroupRoleResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    user_count: number
    permission_count: number
    description: string
  }[]
}>

export const getAppGroupRole = async ({
  application_id,
  group_id,
  ...params
}: TAppGroupRoleRequest) => {
  return Api.get<TAppGroupRoleResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/groups/${group_id}/roles`,
    {
      params
    }
  ).then(successResolver)
}
/** ********************************************************************
 * APPLICATION ROLES
 */

// APP ROLE LIST API
export type TAppRoleListRequest = APIWithPager<
  APIWithSearch<{ application_id: TID; country_id?: TID }>
>

export type TAppRoleListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    user_count: number
    permission_count: number
    group: {
      id: number
      name: string
      country_id: number
    }
    description: string
  }[]
}>

// GET ROLE LIST API
export const getAppRoleList = async ({
  application_id,
  ...params
}: TAppRoleListRequest) => {
  return Api.get<TAppRoleListResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/roles`,
    {
      params
    }
  ).then(successResolver)
}

// GET ROLE DETAIL API
export type TAppRoleDetailRequest = {
  application_id: TID
  role_id: TID
}

export type TAppRoleDetail = {
  id: number
  name: string
  group: {
    id: number
    name: string
    country_id: number
  }
  description: string
  permissions: number[]
}

export const getAppRoleDetail = async ({
  application_id,
  role_id
}: TAppRoleDetailRequest) => {
  return Api.get<AxiosResponse<TAppRoleDetail>>(
    `/${APP_API_VERSION}/applications/${application_id}/roles/${role_id}`
  ).then(successResolver)
}

export type TAppRolePayload = {
  name: string
  group_id: number
  description: string
  permissions: TID[]
}

// CREATE ROLE API
export const postAppRole = async (appId: TID, payload: TAppRolePayload) => {
  return Api.post(
    `/${APP_API_VERSION}/applications/${appId}/roles`,
    payload
  ).then(successResolver)
}

// UPDATE ROLE API
export const putAppRole = async (
  appId: TID,
  roleId: TID,
  payload: TAppRolePayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/roles/${roleId}`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION PERMISSIONS
 */

// APP PERMISSION LIST API
export type TAppPermissionListRequest = APIWithPager<
  APIWithSearch<{ application_id: TID }>
>

export type TAppPermissionListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    module_name: string
    created_at: string
  }[]
}>

// GET PERMISSION LIST API
export const getAppPermissionList = async ({
  application_id,
  ...params
}: TAppPermissionListRequest) => {
  return Api.get<TAppPermissionListResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/permissions`,
    {
      params
    }
  ).then(successResolver)
}

// GET PERMISSION DETAIL API
export type TAppPermissionDetailRequest = {
  application_id: TID
  permission_id: TID
}

export type TAppPermissionDetail = {
  id: number
  name: string
  module_name: string
  description: string
  roles: {
    id: number
    name: string
    country: {
      id: number
      name: string
      country_code: string
    }
  }[]
}

export const getAppPermissionDetail = async ({
  application_id,
  permission_id
}: TAppPermissionDetailRequest) => {
  return Api.get<AxiosResponse<TAppPermissionDetail>>(
    `/${APP_API_VERSION}/applications/${application_id}/permissions/${permission_id}`
  ).then(successResolver)
}

export type TAppPermissionPayload = {
  name: string
  module_name: string
  description: string
  roles: number[]
}

// CREATE PERMISSION API
export const postAppPermission = async (
  appId: TID,
  payload: TAppPermissionPayload
) => {
  return Api.post(
    `/${APP_API_VERSION}/applications/${appId}/permissions`,
    payload
  ).then(successResolver)
}

// UPDATE PERMISSION API
export const putAppPermission = async (
  appId: TID,
  permissionId: TID,
  payload: TAppPermissionPayload
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/permissions/${permissionId}`,
    payload
  ).then(successResolver)
}

/** ********************************************************************
 * APPLICATION USERS
 */

// APP USER LIST API
export type TAppUserListRequest = APIWithPager<
  APIWithSearch<{ application_id: TID }>
>

export type TAppUserListResponse = APIWithPagerMeta<{
  data: {
    id: number
    name: string
    email: string
    role_count: number
    group_count: number
    status: 'active' | 'inactive'
  }[]
}>

// GET USER LIST API
export const getAppUserList = async ({
  application_id,
  ...params
}: TAppUserListRequest) => {
  return Api.get<TAppUserListResponse>(
    `/${APP_API_VERSION}/applications/${application_id}/users`,
    {
      params
    }
  ).then(successResolver)
}

// GET USER DETAIL API
export type TAppUserDetailRequest = {
  application_id: TID
  user_id: TID
}

export type TAppUserDetail = {
  id: string
  name: string
  email: string
  date_of_birth: string
  status: 'active' | 'inactive'
  gender: string
  address: string
  phone_no: string
  phone_code: string
  roles: {
    id: number
    name: string
    country: {
      id: number
      name: string
      country_code: string
    }
    group: {
      id: number
      name: string
    }
  }[]
  password_reset_link_expired_in: number | null
}

export const getAppUserDetail = async ({
  application_id,
  user_id
}: TAppUserDetailRequest) => {
  return Api.get<AxiosResponse<TAppUserDetail>>(
    `/${APP_API_VERSION}/applications/${application_id}/users/${user_id}`
  ).then(successResolver)
}

export type TAppUserPayload = {
  name: string
  email: string
  date_of_birth: string
  gender: string
  address: string
  phone_no: string
  password: string
  phone_code: string
  status?: string
  roles: number[]
}

// CREATE USER API
export const postAppUser = async (appId: TID, payload: TAppUserPayload) => {
  return Api.post(
    `/${APP_API_VERSION}/applications/${appId}/users`,
    payload
  ).then(successResolver)
}

// UPDATE USER API
export const putAppUser = async (
  appId: TID,
  userId: TID,
  payload: TAppUserPayload & { is_password_updated: boolean }
) => {
  return Api.put(
    `/${APP_API_VERSION}/applications/${appId}/users/${userId}`,
    payload
  ).then(successResolver)
}

// POST RESET PASSWORD LINK API
export const postResetPasswordLink = async ({
  appId,
  userId
}: {
  appId: TID
  userId: TID
}) => {
  return Api.post<AxiosResponse<{ url: string }>>(
    `/${APP_API_VERSION}/applications/${appId}/users/${userId}/reset-password`
  ).then(successResolver)
}

// DELETE RESET PASSWORD LINK API
export const deleteResetPasswordLink = async ({
  appId,
  userId
}: {
  appId: TID
  userId: TID
}) => {
  return Api.delete(
    `/${APP_API_VERSION}/applications/${appId}/users/${userId}/reset-password`
  ).then(successResolver)
}

/** ********************************************************************
 * PASSWORD POCLICY
 */

export type TPasswordPolicy = {
  id: number
  name: string
  is_enabled: boolean
  min_length: number | null
}

export const getPasswordPolicy = async () => {
  return Api.get<AxiosResponse<TPasswordPolicy[]>>(
    `/${APP_API_VERSION}/password-policies`
  ).then(successResolver)
}

export type TPasswordPolicyPayload = Omit<TPasswordPolicy, 'id'>

export const putPasswordPolicy = async (payload: {
  policies: TPasswordPolicyPayload[]
}) => {
  return Api.put(`/${APP_API_VERSION}/password-policies`, payload).then(
    successResolver
  )
}
