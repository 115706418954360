import AppRoleForm from '$blocks/AppRole/Form'
import { DEFAULT_FORM_VALUES } from '$blocks/AppRole/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'

const AppRoleCreate = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[
          { name: `${appName} Roles`, path: ROUTE_NAMES.APP_ROLES },
          { name: `Add New Role` }
        ]}
      />

      <AppRoleForm appName={appName} defaultValues={DEFAULT_FORM_VALUES} />
    </>
  )
}

export default AppRoleCreate
