import { useAppScopeStore } from '$hooks/stores'
import { getAppScope } from '$services/api'
import { useRequest } from 'ahooks'

export const useAppScopeService = () => {
  const updateAppScopes = useAppScopeStore(state => state.updateAppScopes)

  const { run: fetchAppScope, loading: fetchingAppScope } = useRequest(
    getAppScope,
    {
      manual: true,
      onSuccess: ({ data }) => {
        const app_scopes = data.map(v => ({
          value: `${v.id}`,
          label: v.value.toLocaleUpperCase()
        }))
        updateAppScopes(app_scopes)
      }
    }
  )

  return {
    fetchAppScope,
    fetchingAppScope
  }
}
