import { CommandKeyIcon } from '$assets/svg'
import { useOS } from '$hooks/actions'

const ModKey = ({ className }: { className?: string }) => {
  const { os } = useOS()

  return os === 'mac' ? (
    <CommandKeyIcon className={className} />
  ) : (
    <span className={className}>Ctrl</span>
  )
}

export default ModKey
