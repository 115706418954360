import { Check, Code2, LucideIcon, Pointer, Search, User2 } from 'lucide-react'

export const APP_ENV_STATE_DATA: Record<
  number,
  { Icon: LucideIcon; background: string; foreground: string }
> = {
  1: {
    Icon: Code2,
    background: 'bg-[--colors-alphaSecondary-1]',
    foreground: 'text-[--colors-secondary-80]'
  },
  2: {
    Icon: User2,
    background: 'bg-[--colors-alphaPrimary-1]',
    foreground: 'text-[--colors-info-80]'
  },
  3: {
    Icon: Search,
    background: 'bg-[--colors-alphaTertiary-1]',
    foreground: 'text-[--colors-tertiary-80]'
  },
  4: {
    Icon: Pointer,
    background: 'bg-[--colors-alphaWarning-1]',
    foreground: 'text-[--colors-warning-80]'
  },
  5: {
    Icon: Check,
    background: 'bg-[hsla(18,100%,50%,0.1)]',
    foreground: 'text-[hsla(18,100%,50%,1)]'
  }
}
