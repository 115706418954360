import AppRoleForm from '$blocks/AppRole/Form'
import {
  DEFAULT_FORM_VALUES,
  TFormValues
} from '$blocks/AppRole/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppRoleService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import { useMemo } from 'react'

const AppRoleEdit = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const {
    route: { params }
  } = useRouteSummary()

  const { roleId, appId } = params

  const { role, fetchingAppRoleDetail, fetchAppRoleDetail } =
    useAppRoleService()

  useMount(() => {
    if (!appId || !roleId) return
    fetchAppRoleDetail({ application_id: appId, role_id: roleId })
  })

  const defaultValues = useMemo(() => {
    if (!role) return DEFAULT_FORM_VALUES

    const { group, ...rest } = role

    const modifiedData: TFormValues = {
      country_id: `${group.country_id}`,
      group: { label: group.name, value: `${group.id}` },
      ...rest
    }

    return modifiedData
  }, [role])

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[
          { name: `${appName} Roles`, path: ROUTE_NAMES.APP_ROLES },
          { name: `${role?.name ?? ''}` }
        ]}
      />

      {fetchingAppRoleDetail && <Loading />}

      {!fetchingAppRoleDetail && (
        <AppRoleForm appName={appName} defaultValues={defaultValues} />
      )}
    </>
  )
}

export default AppRoleEdit
