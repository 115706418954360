import CSVImport from '$blocks/CSVImport'

const AppRoleCSVImport = () => {
  return (
    <article className="flex py-5">
      <CSVImport type="role" />
    </article>
  )
}

export default AppRoleCSVImport
