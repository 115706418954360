export const RoleIcon = () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50065 5.00016C7.50065 4.63197 7.79913 4.3335 8.16732 4.3335H13.5007C13.8688 4.3335 14.1673 4.63197 14.1673 5.00016C14.1673 5.36835 13.8688 5.66683 13.5007 5.66683H8.16732C7.79913 5.66683 7.50065 5.36835 7.50065 5.00016Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.16732 1.00016C4.16732 0.631973 4.46579 0.333496 4.83398 0.333496H13.5007C13.8688 0.333496 14.1673 0.631973 14.1673 1.00016C14.1673 1.36835 13.8688 1.66683 13.5007 1.66683H4.83398C4.46579 1.66683 4.16732 1.36835 4.16732 1.00016Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50065 9.00016C7.50065 8.63197 7.79913 8.3335 8.16732 8.3335H13.5007C13.8688 8.3335 14.1673 8.63197 14.1673 9.00016C14.1673 9.36835 13.8688 9.66683 13.5007 9.66683H8.16732C7.79913 9.66683 7.50065 9.36835 7.50065 9.00016Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50065 0.333496C1.86884 0.333496 2.16732 0.631973 2.16732 1.00016V3.66683C2.16732 4.03197 2.46884 4.3335 2.83398 4.3335H4.83398C5.20217 4.3335 5.50065 4.63197 5.50065 5.00016C5.50065 5.36835 5.20217 5.66683 4.83398 5.66683H2.83398C1.73246 5.66683 0.833984 4.76835 0.833984 3.66683V1.00016C0.833984 0.631973 1.13246 0.333496 1.50065 0.333496Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50065 3.00016C1.86884 3.00016 2.16732 3.29864 2.16732 3.66683V7.66683C2.16732 8.03197 2.46884 8.3335 2.83398 8.3335H4.83398C5.20217 8.3335 5.50065 8.63197 5.50065 9.00016C5.50065 9.36835 5.20217 9.66683 4.83398 9.66683H2.83398C1.73246 9.66683 0.833984 8.76835 0.833984 7.66683V3.66683C0.833984 3.29864 1.13246 3.00016 1.50065 3.00016Z"
        fill="currentColor"
      />
    </svg>
  )
}
