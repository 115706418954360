export const UserIcon = () => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.03285 7.36667C2.90802 6.4915 4.09501 5.99984 5.33268 5.99984C6.57036 5.99984 7.75734 6.4915 8.63251 7.36667C9.50768 8.24184 9.99935 9.42883 9.99935 10.6665C9.99935 11.0347 9.70087 11.3332 9.33268 11.3332C8.96449 11.3332 8.66602 11.0347 8.66602 10.6665C8.66602 9.78245 8.31483 8.9346 7.6897 8.30948C7.06458 7.68436 6.21674 7.33317 5.33268 7.33317C4.44863 7.33317 3.60078 7.68436 2.97566 8.30948C2.35054 8.9346 1.99935 9.78245 1.99935 10.6665C1.99935 11.0347 1.70087 11.3332 1.33268 11.3332C0.964492 11.3332 0.666016 11.0347 0.666016 10.6665C0.666016 9.42883 1.15768 8.24184 2.03285 7.36667Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33268 1.99984C4.22811 1.99984 3.33268 2.89527 3.33268 3.99984C3.33268 5.10441 4.22811 5.99984 5.33268 5.99984C6.43725 5.99984 7.33268 5.10441 7.33268 3.99984C7.33268 2.89527 6.43725 1.99984 5.33268 1.99984ZM1.99935 3.99984C1.99935 2.15889 3.49173 0.666504 5.33268 0.666504C7.17363 0.666504 8.66602 2.15889 8.66602 3.99984C8.66602 5.84079 7.17363 7.33317 5.33268 7.33317C3.49173 7.33317 1.99935 5.84079 1.99935 3.99984Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99935 1.33317C9.99935 0.964981 10.2978 0.666504 10.666 0.666504C11.5501 0.666504 12.3979 1.01769 13.023 1.64281C13.6482 2.26794 13.9993 3.11578 13.9993 3.99984C13.9993 4.88389 13.6482 5.73174 13.023 6.35686C12.9616 6.4183 12.898 6.4771 12.8324 6.53317C13.2465 6.75016 13.6292 7.03005 13.9658 7.36667C14.841 8.24184 15.3327 9.42883 15.3327 10.6665C15.3327 11.0347 15.0342 11.3332 14.666 11.3332C14.2978 11.3332 13.9993 11.0347 13.9993 10.6665C13.9993 9.78245 13.6482 8.9346 13.023 8.30948C12.3979 7.68436 11.5501 7.33317 10.666 7.33317C10.2978 7.33317 9.99935 7.03469 9.99935 6.6665C9.99935 6.29831 10.2978 5.99984 10.666 5.99984C11.1964 5.99984 11.7052 5.78912 12.0802 5.41405C12.4553 5.03898 12.666 4.53027 12.666 3.99984C12.666 3.4694 12.4553 2.9607 12.0802 2.58562C11.7052 2.21055 11.1964 1.99984 10.666 1.99984C10.2978 1.99984 9.99935 1.70136 9.99935 1.33317Z"
        fill="currentColor"
      />
    </svg>
  )
}
