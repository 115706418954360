import { TOOLTIP_ID } from '$app/constants'
import { cn } from '$app/utils'
import { Icon } from '@genie-fintech/ui/icons'
import React, { PropsWithChildren, useCallback, useState } from 'react'

export const CopyButton = ({
  value,
  size = 24,
  classNames,
  children
}: PropsWithChildren<{
  value: string
  size?: number
  classNames?: { container?: string; content?: string }
}>) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      // Create a Blob for the HTML content
      const htmlBlob = new Blob([value], { type: 'text/html' })
      // Create a Blob for the plain text content
      const textBlob = new Blob([value], { type: 'text/plain' })

      // Create a ClipboardItem with the appropriate MIME types
      const clipboardItem = new ClipboardItem({
        'text/html': htmlBlob,
        'text/plain': textBlob
      })

      navigator.clipboard.writeText(value)

      navigator.clipboard.write([clipboardItem]).then(() => {
        setIsCopied(true)
      })
    },
    [value]
  )

  return (
    <button
      type="button"
      className={classNames?.container}
      onClick={handleCopy}
      onBlur={() => setIsCopied(false)}
      data-tooltip-id={TOOLTIP_ID}
      data-tooltip-content={isCopied ? 'Copied' : 'Copy'}
    >
      {!!children && children}
      <Icon
        namespace={isCopied ? 'Check' : 'Copy'}
        width={size}
        height={size}
        className={cn(
          isCopied
            ? 'text-[--colors-success-default]'
            : 'text-[--colors-neutral-70]',
          classNames?.content
        )}
      />
    </button>
  )
}
