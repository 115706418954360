import { importDataApi } from '$services/api'
import { useRequest } from 'ahooks'

export const useImportService = () => {
  const {
    run: importData,
    runAsync: importDataAsync,
    loading: importingData
  } = useRequest(importDataApi, { manual: true })

  return {
    importData,
    importDataAsync,
    importingData
  }
}
