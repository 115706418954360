import { useEffect } from 'react'

import { useRoute } from '$contexts/RouteContext/hooks'
import { getAppTitle } from '$app/utils'

export const useRouteMeta = () => {
  const { is404, info } = useRoute()

  useEffect(() => {
    if (!info?.meta) return

    const { title } = { ...info?.meta }

    const value = is404 ? 'Page not found' : title

    document.title = getAppTitle({ title: value })
  }, [info, is404])
}

export default useRouteMeta
