import { deleteByAppId } from '$services/api'
import { useRequest } from 'ahooks'

export const useDeleteService = () => {
  const {
    run: deleteItemByAppId,
    runAsync: deleteItemByAppIdAsync,
    loading: deletingItemByAppId
  } = useRequest(deleteByAppId, { manual: true })

  return {
    deleteItemByAppId,
    deleteItemByAppIdAsync,
    deletingItemByAppId
  }
}
