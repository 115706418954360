export const LaunchPadIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 3.5C1.91421 3.5 2.25 3.83579 2.25 4.25V11.75C2.25 12.1642 1.91421 12.5 1.5 12.5C1.08579 12.5 0.75 12.1642 0.75 11.75V4.25C0.75 3.83579 1.08579 3.5 1.5 3.5Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2C4.91421 2 5.25 2.33579 5.25 2.75V13.25C5.25 13.6642 4.91421 14 4.5 14C4.08579 14 3.75 13.6642 3.75 13.25V2.75C3.75 2.33579 4.08579 2 4.5 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2C8.58579 2 8.25 2.33579 8.25 2.75V13.25C8.25 13.6642 8.58579 14 9 14H15C15.4142 14 15.75 13.6642 15.75 13.25V2.75C15.75 2.33579 15.4142 2 15 2H9ZM6.75 2.75C6.75 1.50736 7.75736 0.5 9 0.5H15C16.2426 0.5 17.25 1.50736 17.25 2.75V13.25C17.25 14.4926 16.2426 15.5 15 15.5H9C7.75736 15.5 6.75 14.4926 6.75 13.25V2.75Z"
        fill="currentColor"
      />
    </svg>
  )
}
