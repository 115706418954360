import { redirect, RouteNames } from '$router/config'
import { useMount } from 'ahooks'

const Navigate = ({ to, replace }: { to: RouteNames; replace?: boolean }) => {
  useMount(() => {
    redirect(to, { replace })
    return
  })

  return null
}

export default Navigate
