import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppPublishUnpublishService } from '$hooks/services/useAppPublishUnpublishService'
import { useAppDetailStore } from '$hooks/stores'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { useCallback, useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { useAppBasicSettingService } from '$hooks/services'
import Switch from '$components/Switch'

const PublishUnpublishButton = ({
  callbackFn
}: {
  callbackFn?: VoidFunction
}) => {
  const [showConfirm, setShowConfirm] = useState(false)

  const {
    route: {
      params: { appId }
    }
  } = useRouteSummary()

  const {
    fetchPublishAppAsync,
    fetchingPublishApp,
    fetchUnpublishAppAsync,
    fetchingUnpublishApp
  } = useAppPublishUnpublishService()

  const { fetchAppBasicSetting } = useAppBasicSettingService()

  const is_published =
    useAppDetailStore(state => state.appDetail?.is_published) ?? false

  const handleOnClick = useCallback(() => {
    setShowConfirm(true)
  }, [])

  const handleOnCancel = useCallback(() => {
    setShowConfirm(false)
  }, [])

  const handleOnConfirm = useCallback(() => {
    setShowConfirm(false)

    if (!appId) return

    const fn = is_published ? fetchUnpublishAppAsync : fetchPublishAppAsync

    fn.bind({ application_id: appId })

    fn({ application_id: appId }).then(() => {
      fetchAppBasicSetting({ application_id: appId })
      callbackFn?.()
    })
  }, [
    appId,
    is_published,
    fetchPublishAppAsync,
    fetchUnpublishAppAsync,
    fetchAppBasicSetting,
    callbackFn
  ])

  const isProcessing = fetchingPublishApp || fetchingUnpublishApp

  return (
    <Dialog.Root open={showConfirm}>
      <article className="w-[50px] flex items-center justify-center">
        {isProcessing ? (
          <Spinner />
        ) : (
          <Switch checked={is_published} onChange={handleOnClick} />
        )}
      </article>

      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent"
          aria-describedby=""
          style={{ maxWidth: '50vw' }}
        >
          <article className="flex flex-col p-5 gap-y-2 min-w-[300px]">
            <Dialog.Title>
              <header className="text-xl font-semibold">Are you sure?</header>
            </Dialog.Title>

            <p className="text-[--colors-neutral-50] text-sm pb-2">
              This app will be {is_published ? 'unpublished' : 'published'}!
            </p>

            <footer className="flex gap-x-2 items-center justify-end border-t border-[--colors-neutral-10] pt-2">
              <Button
                styleVariants={{ type: 'outlined' }}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>

              <Button onClick={handleOnConfirm}>
                {is_published ? 'Unpublish' : 'Publish'}
              </Button>
            </footer>
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default PublishUnpublishButton
