import { cn } from '$app/utils'
import { GroupBlackAndWhiteIcon } from '$assets/svg'

interface IGroupLogoProps {
  country?: {
    id: number
    code: string
  }
}

const bgClassName: Record<number, string> = {
  1: 'bg-[--colors-secondary-70]',
  2: 'bg-[--colors-tertiary-70]',
  3: 'bg-[--colors-primary-70]',
  4: 'bg-[--colors-danger-70]',
  5: 'bg-[--colors-success-70]',
  6: 'bg-[--colors-warning-70]'
}

const GroupLogo = ({ country }: IGroupLogoProps) => {
  return (
    <article className="inline-flex relative">
      <GroupBlackAndWhiteIcon />

      {country && (
        <span
          className={cn(
            'w-4 h-4 absolute flex items-center justify-center text-[--colors-absolute-light] bottom-0 right-0 text-[6px] font-semibold rounded-full -mb-2 -mr-2',
            bgClassName[country.id]
          )}
        >
          {country?.code}
        </span>
      )}
    </article>
  )
}

export default GroupLogo
