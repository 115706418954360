export const TokenIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87661 1.05204C7.21826 0.855165 7.60566 0.751526 8 0.751526C8.39435 0.751526 8.78177 0.855173 9.12343 1.05207L9.125 1.05297L14.375 4.05296C14.7167 4.25025 15.0005 4.53392 15.198 4.87553C15.3954 5.21713 15.4996 5.60465 15.5 5.99922V12.0008C15.4996 12.3953 15.3954 12.7828 15.198 13.1244C15.0005 13.4661 14.7167 13.7497 14.375 13.947L14.3721 13.9487L9.125 16.947L9.12365 16.9478C8.78194 17.1448 8.39444 17.2485 8 17.2485C7.60558 17.2485 7.21809 17.1448 6.87639 16.9478L6.875 16.947L1.6279 13.9487L1.625 13.947C1.2833 13.7497 0.999478 13.4661 0.802019 13.1244C0.604561 12.7828 0.500405 12.3953 0.5 12.0008V5.99922C0.500405 5.60465 0.604561 5.21713 0.802019 4.87553C0.999478 4.53392 1.2833 4.25025 1.625 4.05297L1.62789 4.0513L6.87661 1.05204ZM8 2.25153C7.86835 2.25153 7.73901 2.28618 7.625 2.35201L7.62211 2.35368L2.375 5.35201L2.37382 5.35269C2.26044 5.41842 2.16626 5.51272 2.10067 5.62619C2.03491 5.73997 2.00019 5.86902 2 6.00043V11.9995C2.00019 12.131 2.03491 12.26 2.10067 12.3738C2.16626 12.4873 2.26044 12.5816 2.37382 12.6473L2.375 12.648L7.625 15.648C7.73901 15.7138 7.86835 15.7485 8 15.7485C8.13165 15.7485 8.26098 15.7138 8.375 15.648L8.3779 15.6463L13.625 12.648L13.6262 12.6473C13.7396 12.5815 13.8337 12.4873 13.8993 12.3738C13.9651 12.2599 13.9999 12.1307 14 11.9992V6.00076C13.9999 5.86923 13.9651 5.74006 13.8993 5.62619C13.8337 5.51272 13.7396 5.41843 13.6262 5.35269L13.625 5.35201L8.375 2.35201C8.26098 2.28619 8.13165 2.25153 8 2.25153Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.824739 4.87628C1.03114 4.51715 1.48958 4.39333 1.84871 4.59973L8 8.13495L14.1513 4.59973C14.5104 4.39333 14.9689 4.51715 15.1753 4.87628C15.3817 5.2354 15.2578 5.69385 14.8987 5.90025L8.37371 9.65025C8.14232 9.78323 7.85768 9.78323 7.62629 9.65025L1.10129 5.90025C0.742158 5.69385 0.618343 5.2354 0.824739 4.87628Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.24999C8.41421 8.24999 8.75 8.58577 8.75 8.99999V16.5C8.75 16.9142 8.41421 17.25 8 17.25C7.58579 17.25 7.25 16.9142 7.25 16.5V8.99999C7.25 8.58577 7.58579 8.24999 8 8.24999Z"
        fill="currentColor"
      />
    </svg>
  )
}
