import { getPasswordPolicy, putPasswordPolicy } from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const usePasswordPolicyService = () => {
  const {
    run: fetchPasswordPolicy,
    runAsync: fetchPasswordPolicyAsync,
    loading: fetchingPasswordPolicy,
    data: passwordPolicyData
  } = useRequest(getPasswordPolicy, {
    manual: true
  })

  const { run: updatePasswordPolicy, loading: updatingPasswordPolicy } =
    useRequest(putPasswordPolicy, {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Updated!')
        fetchPasswordPolicy()
      }
    })

  const { data: passwordPolicy = [] } = passwordPolicyData ?? {}

  return {
    fetchPasswordPolicy,
    fetchPasswordPolicyAsync,
    fetchingPasswordPolicy,
    passwordPolicy,

    updatePasswordPolicy,
    updatingPasswordPolicy
  }
}
