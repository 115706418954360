import {
  errorMessageResolver,
  getAppTokenSetting,
  putAppTokenSetting
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppTokenService = () => {
  const {
    run: fetchAppTokenSetting,
    runAsync: fetchAppTokenSettingAsync,
    loading: fetchingAppTokenSetting
  } = useRequest(getAppTokenSetting, {
    manual: true
  })

  const {
    run: updateAppTokenSetting,
    runAsync: updateAppTokenSettingAsync,
    loading: updatingAppTokenSetting
  } = useRequest(putAppTokenSetting, {
    manual: true,
    onSuccess: () => {
      toast.success('Successfully Updated!')
    },
    onError: err => {
      toast.error(errorMessageResolver(err))
    }
  })

  return {
    fetchAppTokenSetting,
    fetchAppTokenSettingAsync,
    fetchingAppTokenSetting,

    updateAppTokenSetting,
    updateAppTokenSettingAsync,
    updatingAppTokenSetting
  }
}
