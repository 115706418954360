import { Icon } from '@genie-fintech/ui/icons'

interface ICloseButtonProps {
  disabled?: boolean
  onClick: VoidFunction
}
const CloseButton = ({ disabled, onClick }: ICloseButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className="w-[40px] h-[40px] grid place-items-center border border-[--colors-neutral-20] rounded-full p-1 hover:bg-[--colors-neutral-20] duration-200"
    >
      <Icon namespace="Cross" color="text.light" />
    </button>
  )
}

export default CloseButton
