import { ROUTE_NAMES } from '$router/config'
import { FolderOpen, LucideIcon, Settings } from 'lucide-react'

export const NavMenus: {
  name: string
  to: ROUTE_NAMES
  Icon: LucideIcon
  subPath: ROUTE_NAMES[]
}[] = [
  {
    name: 'Applications',
    to: ROUTE_NAMES.APPS,
    Icon: FolderOpen,
    subPath: [
      ROUTE_NAMES.APP_HOME,
      ROUTE_NAMES.APP_BASIC_SETTING,
      ROUTE_NAMES.APP_ADVANCED_SETTING,
      ROUTE_NAMES.APP_LAUNCHPAD_SETTING,
      ROUTE_NAMES.APP_TOKEN_SETTING,
      ROUTE_NAMES.APP_GROUPS,
      ROUTE_NAMES.APP_GROUP_CREATE,
      ROUTE_NAMES.APP_GROUP_EDIT,
      ROUTE_NAMES.APP_ROLES,
      ROUTE_NAMES.APP_ROLE_CREATE,
      ROUTE_NAMES.APP_ROLE_EDIT,
      ROUTE_NAMES.APP_USERS,
      ROUTE_NAMES.APP_USER_CREATE,
      ROUTE_NAMES.APP_USER_EDIT,
      ROUTE_NAMES.APP_PERMISSIONS,
      ROUTE_NAMES.APP_PERMISSION_CREATE,
      ROUTE_NAMES.APP_PERMISSION_EDIT,
      ROUTE_NAMES.APP_ROLES_CSV_IMPORT,
      ROUTE_NAMES.APP_PERMISSIONS_CSV_IMPORT,
      ROUTE_NAMES.APP_USERS_CSV_IMPORT
    ]
  },
  {
    name: 'GLOBAL SETTINGS',
    to: ROUTE_NAMES.PASSWORD_POLICY,
    Icon: Settings,
    subPath: [ROUTE_NAMES.COUNTRIES]
  }
]
