import { APP_BRANDS } from '$app/constants'
import { cn } from '$app/utils'
import { TAppListResponse } from '$services/api'
import { useMemo } from 'react'

const AppLogo = ({
  logo,
  brand,
  is_published,
  size = 'small'
}: Partial<
  Pick<TAppListResponse['data'][number], 'logo' | 'is_published' | 'brand'>
> & {
  size?: 'small' | 'big'
}) => {
  const containerSize = useMemo(() => {
    if (size === 'big') return 48
    return 36
  }, [size])

  const { url } = logo ?? {}

  const { Icon, background: bg } = APP_BRANDS.find(d => d.name === brand) ?? {}

  const background = url ? 'bg-[--colors-area-disabled]' : bg

  const previewUrl = (() => {
    if (url) return url
    return Icon ?? APP_BRANDS[0].Icon
  })()

  return (
    <article
      className={cn(
        'inline-flex items-center justify-center rounded-lg relative',
        size === 'big' ? 'p-1.5' : 'p-1',
        background
      )}
      style={{ width: containerSize, height: containerSize }}
    >
      <img
        src={previewUrl}
        className="object-contain rounded"
        width={containerSize - 8}
        height={containerSize - 8}
      />

      {is_published && (
        <article
          className={cn(
            'absolute inline-flex items-center justify-center top-0 right-0 w-[14px] h-[14px] rounded-full border-[0.5px] border-[--colors-success-20] -mt-[4px] -mr-[4px] bg-[--colors-text-onBackground]'
          )}
        >
          <span className="w-2 h-2 bg-[--colors-success-default] rounded-full"></span>
        </article>
      )}
    </article>
  )
}

export default AppLogo
