import { useAtom } from '../reactivity/atom'

import {
  autoAtom,
  darkModeAtom,
  toggle,
  auto,
  rotate,
  disable,
  enable
} from '../store/darkMode'

export function useDarkMode() {
  const { state: isAuto } = useAtom(autoAtom)
  const { state: isDarkMode } = useAtom(darkModeAtom)

  return {
    isDarkMode,
    isAuto,
    toggle,
    enable,
    disable,
    auto,
    rotate
  }
}

export default useDarkMode
