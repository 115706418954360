import { useEffect } from 'react'

export const useLeavePageWarning = (enabled = true) => {
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      if (enabled) {
        event.preventDefault()
        // For old browsers
        event.returnValue = false
        // For modern browsers
        return false
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [enabled])
}
