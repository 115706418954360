import { useContext } from 'react'
import { RouteContext } from '../context'

export const useRouterContext = () => useContext(RouteContext)

export const useRouteSummary = () => {
  const { summaryState } = useRouterContext()

  return summaryState
}
export const useRoute = () => {
  const { routeState } = useRouterContext()

  return routeState
}
