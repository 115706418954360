import { useBoolean } from 'ahooks'
import { FC, useImperativeHandle, useRef } from 'react'
import { Simplify } from 'type-fest'

import { TagsNoRef, ToReferences } from '../../../types'
import {
  CommonFieldStateProps,
  CommonTextFieldProps,
  CommonWithAffixProps,
  CommonWithFieldContainerProps
} from '../common/types'

import {
  propsWithClassNames,
  toClassNames,
  trueOrUndefined
} from '../../../functions'

import FieldContainer from '../FieldContainer'

import {
  AffixControl,
  affixControl,
  element,
  elementControl
} from '../common/style.css'

import { container, textarea } from './style.css'

type VNodes = {
  elementControlRef: HTMLDivElement
  textareaRef: HTMLTextAreaElement
}

type References = Partial<ToReferences<VNodes>>

type EleCtrlTagProps = TagsNoRef<'div'>
type InputTagProps = TagsNoRef<'textarea'>

type ElementControlProps = CommonWithAffixProps &
  CommonTextFieldProps &
  References & {
    elementControlProps?: EleCtrlTagProps
    textareaProps?: InputTagProps
  }

export type BaseTextareaProps = Simplify<
  CommonWithFieldContainerProps & ElementControlProps
>

export const BaseTextarea: FC<BaseTextareaProps> = ({
  textareaProps,
  elementControlProps,
  placeholder,
  affix,
  textareaRef,
  elementControlRef,
  ...restFieldContainerProps
}) => {
  const textareaNode = useRef<VNodes['textareaRef']>(null)

  const { disabled, error, success } = restFieldContainerProps
  const { post: suffix, pre: prefix } = { ...affix }
  const {
    onFocus: onFocusNative,
    onBlur: onBlurNative,
    className,
    ...restInputTagProps
  } = { ...textareaProps }
  const { onClick: controlNativeOnclick, ...restElementControlProps } = {
    ...elementControlProps
  }

  const [isFocus, { setFalse, setTrue }] = useBoolean()

  const fieldContainerProps: CommonWithFieldContainerProps = {
    ...restFieldContainerProps,
    containerProps: propsWithClassNames(
      restFieldContainerProps.containerProps,
      container
    )
  }

  const stateProps: CommonFieldStateProps = {
    'data-success': trueOrUndefined(success),
    'data-error': trueOrUndefined(error),
    'data-disabled': trueOrUndefined(disabled),
    'data-focus': trueOrUndefined(isFocus)
  }

  const position: AffixControl['position'] = (() => {
    const hasPrefix = !!prefix
    const hasSuffix = !!suffix

    if (hasPrefix && hasSuffix) {
      return 'both'
    } else if (hasPrefix) {
      return 'pre'
    } else if (hasSuffix) {
      return 'post'
    }
  })()

  const textareaClassName = toClassNames(textarea, element, className)
  const controlClassName = toClassNames(
    elementControl,
    affixControl({ position })
  )

  // Auto-focus textarea upon wrapper click
  const controlOnClick: EleCtrlTagProps['onClick'] = (...args) => {
    textareaNode.current?.focus()
    controlNativeOnclick?.(...args)
  }

  const onFocus: InputTagProps['onFocus'] = (...args) => {
    setTrue()
    onFocusNative?.(...args)
  }

  const onBlur: InputTagProps['onBlur'] = (...args) => {
    setFalse()
    onBlurNative?.(...args)
  }

  useImperativeHandle(textareaRef, () => textareaNode.current as never)

  return (
    <FieldContainer {...fieldContainerProps}>
      {({ elementProps: { id } }) => (
        <div
          ref={elementControlRef}
          className={controlClassName}
          onClick={controlOnClick}
          {...stateProps}
          {...restElementControlProps}
        >
          {prefix}
          <textarea
            ref={textareaNode}
            className={textareaClassName}
            {...stateProps}
            {...{ id, placeholder, onFocus, onBlur, disabled }}
            {...restInputTagProps}
          />
          {suffix}
        </div>
      )}
    </FieldContainer>
  )
}

export default BaseTextarea
