import { useEffect } from 'react'
import { useOS } from './useOS'

const Key = 'k'

export const useFocusEventLister = (callback: VoidFunction) => {
  const { os } = useOS()

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (
        (os === 'mac' && event.metaKey && event.key === Key) ||
        ((os === 'windows' || os === 'linux') &&
          event.ctrlKey &&
          event.key === Key)
      ) {
        event.preventDefault()
        callback()
      }
    }

    window.addEventListener('keydown', handleKeydown)

    return () => {
      window.removeEventListener('keydown', handleKeydown)
    }
  }, [os, callback])
}
