import {
  getAppGroupDetail,
  getAppGroupList,
  getAppGroupRole,
  getAppGroupSelectList,
  getAppGroupUser,
  postAppGroup,
  putAppGroup
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppGroupService = () => {
  const {
    run: fetchAppGroupList,
    runAsync: fetchAppGroupListAsync,
    data: AppGroupListData,
    loading: fetchingAppGroupList
  } = useRequest(getAppGroupList, {
    manual: true
  })

  const {
    run: fetchAppGroupDetail,
    runAsync: fetchAppGroupDetailAsync,
    data: appGroupDatail,
    loading: fetchingAppGroupDetail
  } = useRequest(getAppGroupDetail, {
    manual: true
  })

  const { runAsync: saveGroupAsync, loading: savingGroup } = useRequest(
    postAppGroup,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Created!')
      }
    }
  )

  const { runAsync: updateGroupAsync, loading: updatingGroup } = useRequest(
    putAppGroup,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Updated!')
      }
    }
  )

  const {
    run: fetchAppGroupUser,
    loading: fetchingAppGroupUser,
    data: appGroupUserData
  } = useRequest(getAppGroupUser, {
    manual: true
  })

  const {
    run: fetchAppGroupRole,
    loading: fetchingAppGroupRole,
    data: appGroupRoleData
  } = useRequest(getAppGroupRole, {
    manual: true
  })

  const {
    run: fetchGroupSelectList,
    runAsync: fetchGroupSelectListAsync,
    loading: fetchingGroupSelectList,
    data: groupSelectListData
  } = useRequest(getAppGroupSelectList, {
    manual: true
  })

  const { data: list = [], meta } = AppGroupListData ?? {}

  const { data: appGroupUsers = [], meta: appGroupUsersMeta } =
    appGroupUserData ?? {}

  const { data: appGroupRoles = [], meta: appGroupRolesMeta } =
    appGroupRoleData ?? {}

  const { data: groups_select_list = [] } = groupSelectListData ?? {}

  const groupOptions = groups_select_list.map(v => ({
    label: v.name,
    value: `${v.id}`
  }))

  return {
    groups: { list, meta },
    fetchAppGroupList,
    fetchingAppGroupList,
    fetchAppGroupListAsync,

    groupOptions,
    fetchGroupSelectList,
    fetchingGroupSelectList,
    fetchGroupSelectListAsync,

    group: appGroupDatail?.data,
    fetchAppGroupDetail,
    fetchAppGroupDetailAsync,
    fetchingAppGroupDetail,

    saveGroupAsync,
    savingGroup,

    updateGroupAsync,
    updatingGroup,

    fetchAppGroupUser,
    fetchingAppGroupUser,
    groupUsers: { list: appGroupUsers, meta: appGroupUsersMeta },

    fetchAppGroupRole,
    fetchingAppGroupRole,
    groupRoles: { list: appGroupRoles, meta: appGroupRolesMeta }
  }
}
