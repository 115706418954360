import {
  getAppRoleDetail,
  getAppRoleList,
  postAppRole,
  putAppRole
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppRoleService = () => {
  const {
    run: fetchAppRoleList,
    runAsync: fetchAppRoleListAsync,
    data: AppRoleListData,
    loading: fetchingAppRoleList
  } = useRequest(getAppRoleList, {
    manual: true
  })

  const {
    run: fetchAppRoleDetail,
    runAsync: fetchAppRoleDetailAsync,
    data: appRoleDatail,
    loading: fetchingAppRoleDetail
  } = useRequest(getAppRoleDetail, {
    manual: true
  })

  const { runAsync: saveRoleAsync, loading: savingRole } = useRequest(
    postAppRole,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Created!')
      }
    }
  )

  const { runAsync: updateRoleAsync, loading: updatingRole } = useRequest(
    putAppRole,
    {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Updated!')
      }
    }
  )

  const { data: list = [], meta } = AppRoleListData ?? {}

  return {
    roles: { list, meta },
    fetchAppRoleList,
    fetchingAppRoleList,
    fetchAppRoleListAsync,

    role: appRoleDatail?.data,
    fetchingAppRoleDetail,
    fetchAppRoleDetail,
    fetchAppRoleDetailAsync,

    saveRoleAsync,
    savingRole,

    updateRoleAsync,
    updatingRole
  }
}
