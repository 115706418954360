import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppPermissionService } from '$hooks/services'
import { TAppPermissionListResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import { Button, Checkbox, Pager } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { useEffect } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { TFormValues } from '../Form/constants'
import { PermissionIcon } from '$assets/svg'
import Link from '$router/Link'
import { ROUTE_NAMES } from '$router/config'

const columns: {
  key: keyof Omit<TAppPermissionListResponse['data'][number], 'id'> | 'action'
  value: string
}[] = [
  { key: 'name', value: 'PERMISSION NAME' },
  { key: 'module_name', value: 'MODULE NAME' },
  { key: 'action', value: '' }
]

const AppRolePermissionList = ({ appName }: { appName: string }) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { control } = useFormContext<TFormValues>()

  const { replace, append } = useFieldArray<TFormValues>({
    name: 'permissions' as never,
    control
  })

  const selectedPermissions = useWatch({ name: 'permissions', control })

  const {
    fetchAppPermissionList,
    fetchingAppPermissionList,
    permissions: { list, meta }
  } = useAppPermissionService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId) return
    fetchAppPermissionList({
      page,
      per_page: perPage,
      application_id: appId,
      q: debouncedSearchValue
    })
  }, [appId, fetchAppPermissionList, page, perPage, debouncedSearchValue])

  if (fetchingAppPermissionList) return <Loading />

  return (
    <article className="flex flex-col gap-y-4 bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
      <article className="flex flex-col gap-y-1">
        <p className="font-semibold">{appName} MODULES & PERMISSIONS</p>
        <p className="text-xs text-[--colors-neutral-50]">
          Setup a mobile, web or IoT application to use CARROsso for
          Authentication.
        </p>
      </article>

      <article className="flex gap-x-2 items-center justify-between">
        <article>
          <BaseText
            affix={{
              pre: <Icon namespace="Search" width={16} />,
              post: searchValue ? (
                <button onClick={() => updateSearchValue('')}>
                  <Icon namespace="Cross" width={18} />
                </button>
              ) : undefined
            }}
            inputProps={{
              type: 'text',
              value: searchValue,
              onChange: e => updateSearchValue(e.currentTarget.value),
              placeholder: 'Search here...'
            }}
          />
        </article>

        {!!meta?.total && (
          <p className="text-[--colors-text-disabled] text-sm font-medium">
            {meta.total} PERMISSION{meta.total > 1 && 'S'}
          </p>
        )}
      </article>

      <article className={customTableContainer}>
        <main className={tableContainerInner}>
          <table className={table({ separator: 'line' })}>
            <thead className={customTableHead}>
              <tr className={tableRow}>
                <th style={{ width: 0 }} className={customTableCell}>
                  <Checkbox
                    boxProps={{
                      checked: list.every(d =>
                        selectedPermissions.includes(d.id)
                      ),
                      onCheckedChange: checked => {
                        if (checked) {
                          append(list.map(v => v.id))
                          return
                        }

                        replace(
                          selectedPermissions.filter(
                            d => !list.map(v => v.id).includes(d)
                          )
                        )
                      }
                    }}
                  />
                </th>
                <th style={{ width: 0 }} className={customTableCell}>
                  <PermissionIcon />
                </th>
                {columns.map((col, key) => (
                  <th key={key} className={customTableCell}>
                    {col.value}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className={tableBody}>
              {list.map((data, rowKey) => (
                <tr key={rowKey} className={tableRow}>
                  <td className={customTableCell}>
                    <Checkbox
                      boxProps={{
                        checked: selectedPermissions.includes(data.id),
                        onCheckedChange: checked => {
                          if (checked) {
                            append(data.id)
                            return
                          }

                          replace(
                            selectedPermissions.filter(d => d !== data.id)
                          )
                        }
                      }}
                    />
                  </td>
                  <td style={{ width: 0 }} className={customTableCell}>
                    <PermissionIcon />
                  </td>
                  {columns.map((col, colKey) => (
                    <td key={colKey} className={customTableCell}>
                      {(() => {
                        if (col.key === 'action') {
                          return (
                            <article>
                              <Link
                                to={ROUTE_NAMES.APP_PERMISSION_EDIT}
                                options={{
                                  params: { ...params, permissionId: data.id }
                                }}
                                className="relative"
                              >
                                <Button
                                  styleVariants={{
                                    type: 'text',
                                    category: 'icon',
                                    kind: 'neutral'
                                  }}
                                >
                                  <span className="-rotate-45">
                                    <Icon namespace="Forward" width={18} />
                                  </span>
                                </Button>

                                <span className="sr-only">
                                  Permission Detail
                                </span>
                              </Link>
                            </article>
                          )
                        }

                        return data[col.key] ?? 'N/A'
                      })()}
                    </td>
                  ))}
                </tr>
              ))}

              {!list.length && (
                <tr className={tableRow}>
                  <td
                    colSpan={columns.length}
                    style={{ textAlign: 'center' }}
                    className={customTableCell}
                  >
                    NO DATA
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </main>
      </article>

      {!!list.length && (
        <footer className="flex justify-end bg-[--colors-area-high] p-4">
          <Pager align="right" {...pagerProps} />
        </footer>
      )}
    </article>
  )
}

export default AppRolePermissionList
