import { GENDER_OPTIONS } from '$app/constants'
import AppUserForm from '$blocks/AppUser/Form'
import {
  DEFAULT_FORM_VALUES,
  TFormValues
} from '$blocks/AppUser/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppUserService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import { useMemo } from 'react'

const AppUserEdit = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const {
    route: { params }
  } = useRouteSummary()

  const { appId, userId } = params

  const { user, fetchAppUserDetail, fetchingAppUserDetail } =
    useAppUserService()

  useMount(() => {
    if (!appId || !userId) return
    fetchAppUserDetail({
      application_id: appId,
      user_id: userId
    })
  })

  const defaultValues = useMemo(() => {
    if (!user) return DEFAULT_FORM_VALUES

    const { roles, gender, phone_code, date_of_birth, ...rest } = user

    const modifiedData: TFormValues = {
      roles: roles.map(v => v.id),
      password: '',
      gender: GENDER_OPTIONS.find(d => d.value === gender) ?? {
        label: '',
        value: ''
      },
      date_of_birth: date_of_birth?.split('-').reverse().join('-') ?? '',
      phone_code: { label: phone_code ?? '', value: phone_code ?? '' },
      ...rest
    }

    return modifiedData
  }, [user])

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[
          { name: `${appName} Users`, path: ROUTE_NAMES.APP_USERS },
          { name: `${user?.name ?? ''}` }
        ]}
      />

      {fetchingAppUserDetail && <Loading />}

      {!fetchingAppUserDetail && (
        <AppUserForm
          appName={appName}
          defaultValues={defaultValues}
          roles={user?.roles}
          password_reset_link_expired_in={user?.password_reset_link_expired_in}
        />
      )}
    </>
  )
}

export default AppUserEdit
