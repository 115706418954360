import { cn } from '$app/utils'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { onOneOf, ROUTE_NAMES } from '$router/config'
import Link from '$router/Link'
import { Globe2, Key, LucideIcon } from 'lucide-react'

const SIDE_MENUS: {
  name: string
  path: ROUTE_NAMES
  Icon: LucideIcon
  subPath: ROUTE_NAMES[]
}[] = [
  {
    name: 'Password Policy',
    path: ROUTE_NAMES.PASSWORD_POLICY,
    Icon: Key,
    subPath: []
  },
  {
    name: 'Countries',
    path: ROUTE_NAMES.COUNTRIES,
    Icon: Globe2,
    subPath: [ROUTE_NAMES.COUNTRY_CREATE, ROUTE_NAMES.COUNTRY_EDIT]
  }
]

const Sidebar = () => {
  const { route } = useRouteSummary()

  return (
    <aside className="flex fixed flex-col py-2 w-[236px] gap-y-4 bg-[--colors-area-high] border border-[--colors-neutral-10] rounded-lg shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1]">
      <article className="px-2">
        <p className="text-sm font-medium text-[--colors-neutral-50] px-2">
          GLOBAL SETTINGS
        </p>
      </article>

      <article className="flex flex-1 flex-col gap-y-2">
        {SIDE_MENUS.map((menu, key) => {
          const active = onOneOf(
            menu.subPath ? [menu.path, ...menu.subPath] : [menu.path]
          )

          const Icon = menu.Icon
          return (
            <article className="relative px-2" key={key}>
              <Link
                to={menu.path}
                options={{ ...route }}
                data-active={active}
                className={cn(
                  'flex gap-x-2 p-3 items-center text-sm text-[--colors-neutral-60] rounded',
                  'data-[active=true]:bg-[--colors-area-low] data-[active=true]:font-semibold data-[active=true]:text-[--colors-text-light]',
                  'data-[active=false]:hover:bg-[--colors-area-low] transition-all duration-200'
                )}
              >
                <Icon size={18} />

                <p>{menu.name}</p>

                {active && (
                  <span className="w-[3px] h-full bg-[--colors-primary-default] absolute right-0 rounded-l-lg" />
                )}
              </Link>
            </article>
          )
        })}
      </article>
    </aside>
  )
}

export default Sidebar
