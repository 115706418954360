export const PermissionIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5007 14.1673C12.5007 13.2468 13.2468 12.5007 14.1673 12.5007H17.5006C18.4211 12.5007 19.1673 13.2468 19.1673 14.1673V17.5006C19.1673 18.4211 18.4211 19.1673 17.5006 19.1673H14.1673C13.2468 19.1673 12.5007 18.4211 12.5007 17.5006V14.1673ZM17.5006 14.1673H14.1673V17.5006H17.5006V14.1673Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.833984 14.1673C0.833984 13.2468 1.58018 12.5007 2.50065 12.5007H5.83398C6.75446 12.5007 7.50065 13.2468 7.50065 14.1673V17.5006C7.50065 18.4211 6.75446 19.1673 5.83398 19.1673H2.50065C1.58018 19.1673 0.833984 18.4211 0.833984 17.5006V14.1673ZM5.83398 14.1673H2.50065V17.5006H5.83398V14.1673Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66732 2.50065C6.66732 1.58018 7.41351 0.833984 8.33398 0.833984H11.6673C12.5878 0.833984 13.334 1.58018 13.334 2.50065V5.83398C13.334 6.75446 12.5878 7.50065 11.6673 7.50065H8.33398C7.41351 7.50065 6.66732 6.75446 6.66732 5.83398V2.50065ZM11.6673 2.50065H8.33398V5.83398H11.6673V2.50065Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.82214 9.65547C4.1347 9.34291 4.55862 9.16732 5.00065 9.16732H15.0006C15.4427 9.16732 15.8666 9.34291 16.1792 9.65547C16.4917 9.96803 16.6673 10.392 16.6673 10.834V13.334C16.6673 13.7942 16.2942 14.1673 15.834 14.1673C15.3737 14.1673 15.0006 13.7942 15.0006 13.334V10.834H5.00065V13.334C5.00065 13.7942 4.62755 14.1673 4.16732 14.1673C3.70708 14.1673 3.33398 13.7942 3.33398 13.334V10.834C3.33398 10.392 3.50958 9.96803 3.82214 9.65547Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0007 5.83398C10.4609 5.83398 10.834 6.20708 10.834 6.66732V10.0007C10.834 10.4609 10.4609 10.834 10.0007 10.834C9.54041 10.834 9.16732 10.4609 9.16732 10.0007V6.66732C9.16732 6.20708 9.54041 5.83398 10.0007 5.83398Z"
        fill="currentColor"
      />
    </svg>
  )
}
