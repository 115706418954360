import { z } from 'zod'

export const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  module_name: z.string().trim().min(1, 'Requried!'),
  description: z.string().trim().min(1, 'Requried!'),
  roles: z.number().array()
})

export type TFormValues = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  module_name: '',
  description: '',
  roles: []
}
