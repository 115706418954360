import { z } from 'zod'

export const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  country_code: z.string().trim().min(1, 'Requried!'),
  phone_code: z.string().trim().min(1, 'Requried!'),
  timezone: z.string().trim().min(1, 'Requried!'),
  currency_name: z.string().trim().min(1, 'Requried!'),
  currency_code: z.string().trim().min(1, 'Requried!'),
  currency_symbol: z.string().trim().min(1, 'Requried!')
})

export type TFormValues = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TFormValues = {
  name: '',
  country_code: '',
  phone_code: '',
  timezone: '',
  currency_name: '',
  currency_code: '',
  currency_symbol: ''
}
