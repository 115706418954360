import { cn } from '$app/utils'
import { Logo } from '$assets/svg'
import { NavMenus } from '$layouts/PageLayout/constants'
import { onOneOf } from '$router/config'
import Link from '$router/Link'
import { Menu } from 'lucide-react'
import { useCallback, useState } from 'react'
import Drawer from 'react-modern-drawer'

const SidebarToggleMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleCloseDrawer = useCallback(() => {
    setDrawerOpen(false)
  }, [])

  const handleOpenDrawer = useCallback(() => {
    setDrawerOpen(true)
  }, [])

  return (
    <>
      <button
        className="inline-flex p-2 rounded-lg bg-[hsla(228,20%,16%,1)]"
        onClick={handleOpenDrawer}
      >
        <Menu size={20} className="text-[hsla(228,11%,82%,1)]" />
      </button>

      <Drawer
        open={drawerOpen}
        onClose={handleCloseDrawer}
        direction="left"
        className="flex flex-col p-2 !bg-transparent !shadow-none"
        size={230}
        lockBackgroundScroll
      >
        <article className="flex flex-col flex-1 gap-4 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[0px_12px_24px_12px] shadow-[--colors-alphaNeutral-1] rounded-lg py-4 px-2">
          <article className="text-[--colors-text-neutral]">
            <Logo />
          </article>

          <main className="flex-1 flex flex-col gap-2 overflow-y-auto">
            {NavMenus.map((menu, key) => {
              const Icon = menu.Icon
              return (
                <Link
                  key={key}
                  to={menu.to}
                  data-active={onOneOf([menu.to, ...(menu.subPath ?? [])])}
                  onClick={handleCloseDrawer}
                  className={cn(
                    'flex gap-x-2 px-3 py-2 items-center text-sm rounded duration-500',
                    'data-[active=true]:font-semibold data-[active=true]:bg-[--colors-area-low] data-[active=true]:text-[--colors-primary-default]',
                    'hover:bg-[--colors-area-low] text-[--colors-neutral-60]'
                  )}
                >
                  <Icon size={18} />
                  <p>{menu.name}</p>
                </Link>
              )
            })}
          </main>
        </article>
      </Drawer>
    </>
  )
}

export default SidebarToggleMenu
