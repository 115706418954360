import { ControllerRenderProps, useController } from 'react-hook-form'
import { BaseTextarea, BaseTextareaProps } from '../../fields'
import { HookField } from '../common/types'
import { resolveErrorMessage } from '../common/functions'

type TextareaProps = Required<BaseTextareaProps>['textareaProps']

export type TextareaFieldProps = Omit<
  BaseTextareaProps,
  'textareaProps' | 'textareaRef'
> & {
  textareaProps?: Omit<TextareaProps, keyof ControllerRenderProps>
}

// component
const Textarea: HookField<TextareaFieldProps> = ({
  name,
  control,
  disabled,
  controllerOptions,
  ...fieldProps
}) => {
  const { field, fieldState } = useController({
    name,
    control,
    disabled,
    ...controllerOptions
  })

  const { value = '', ref: textareaRef, ...controlledFieldProps } = field

  const errorMessage = resolveErrorMessage(fieldState.error)
  const hasError = !!errorMessage

  const fieldPropBinding: BaseTextareaProps = {
    disabled,
    error: hasError,
    message: errorMessage,
    ...fieldProps,
    textareaRef,
    textareaProps: {
      value,
      ...fieldProps.textareaProps,
      ...controlledFieldProps
    }
  }
  return <BaseTextarea {...fieldPropBinding} />
}

export default Textarea
