import {
  getAppPermissionDetail,
  getAppPermissionList,
  postAppPermission,
  putAppPermission
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppPermissionService = () => {
  const {
    run: fetchAppPermissionList,
    runAsync: fetchAppPermissionListAsync,
    data: appPermissionListData,
    loading: fetchingAppPermissionList
  } = useRequest(getAppPermissionList, {
    manual: true
  })

  const {
    run: fetchAppPermissionDetail,
    runAsync: fetchAppPermissionDetailAsync,
    data: appPermissionDatail,
    loading: fetchingAppPermissionDetail
  } = useRequest(getAppPermissionDetail, {
    manual: true
  })

  const { runAsync: savePermissionAsync, loading: savingPermission } =
    useRequest(postAppPermission, {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Created!')
      }
    })

  const { runAsync: updatePermissionAsync, loading: updatingPermission } =
    useRequest(putAppPermission, {
      manual: true,
      onSuccess: () => {
        toast.success('Successfully Updated!')
      }
    })

  const { data: list = [], meta } = { ...appPermissionListData }

  return {
    permissions: { list, meta },
    fetchAppPermissionList,
    fetchingAppPermissionList,
    fetchAppPermissionListAsync,

    permission: appPermissionDatail?.data,
    fetchingAppPermissionDetail,
    fetchAppPermissionDetail,
    fetchAppPermissionDetailAsync,

    savePermissionAsync,
    savingPermission,

    updatePermissionAsync,
    updatingPermission
  }
}
