import CountryForm from '$blocks/Country/Form'
import { DEFAULT_FORM_VALUES } from '$blocks/Country/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import { ROUTE_NAMES } from '$router/config'

const CountryCreate = () => {
  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.COUNTRIES}
        data={[{ name: `Add New Group` }]}
      />

      <CountryForm defaultValues={DEFAULT_FORM_VALUES} />
    </>
  )
}

export default CountryCreate
