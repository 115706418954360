import { useAppDetailStore } from '$hooks/stores'
import { getAppBasicSetting, putAppBasicSetting } from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppBasicSettingService = () => {
  const updateAppBasicSettingStore = useAppDetailStore(
    state => state.updateAppDetail
  )

  const {
    run: fetchAppBasicSetting,
    runAsync: fetchAppBasicSettingAsync,
    loading: fetchingAppBasicSetting,
    data: appBasicSettingData
  } = useRequest(getAppBasicSetting, {
    manual: true,
    onSuccess: ({ data }) => {
      updateAppBasicSettingStore(data)
    }
  })

  const {
    run: updateAppBasicSetting,
    runAsync: updateAppBasicSettingAsync,
    loading: updatingAppBasicSetting
  } = useRequest(putAppBasicSetting, {
    manual: true,
    onSuccess: () => {
      toast.success('Successfully Updated!')
    }
  })

  const { data: appBasicSetting } = appBasicSettingData ?? {}

  return {
    fetchAppBasicSetting,
    fetchAppBasicSettingAsync,
    fetchingAppBasicSetting,
    appBasicSetting,

    updateAppBasicSetting,
    updateAppBasicSettingAsync,
    updatingAppBasicSetting
  }
}
