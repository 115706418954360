import { useAuthContext } from '$contexts/AuthContext/hooks'
import { useLoginLogoutService } from '$hooks/services'
import { Avatar } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

const Profile = () => {
  const { user } = useAuthContext()

  const { logout } = useLoginLogoutService()

  if (!user) return null

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <button className="flex items-center gap-2 px-2 py-1 rounded-lg hover:outline-1 hover:outline hover:outline-[--colors-absolute-light] focus:outline-none">
          <Avatar size={32} status="online" />

          <article className="flex flex-col items-start">
            <p className="text-xs font-semibold text-[--colors-absolute-light]">
              {user.name}
            </p>
            <p className="text-xs text-[--colors-neutral-60] capitalize">
              {user.role}
            </p>
          </article>

          <Icon namespace="Down" color="absolute.light" width={18} />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className="bg-[--colors-area-high] p-1 rounded-lg min-w-[150px] z-20 border border-[--colors-neutral-20]"
          sideOffset={5}
          side="bottom"
        >
          <DropdownMenu.Item
            onClick={logout}
            className="flex hover:bg-[--colors-neutral-20] hover:outline-none p-2 rounded-lg text-sm cursor-pointer"
          >
            <article className="flex-1 flex justify-between items-center">
              Logout
              <Icon namespace="LogOut" width={16} />
            </article>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default Profile
