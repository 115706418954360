import { PermissionIcon, RoleIcon } from '$assets/svg'
import Breadcrumb from '$components/Breadcrumb'
import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppPermissionService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { redirect, ROUTE_NAMES } from '$router/config'
import Link from '$router/Link'
import { TAppPermissionListResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import { Button, Pager } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { format } from 'date-fns'
import { Table2 } from 'lucide-react'
import { useCallback, useEffect } from 'react'

const columns: {
  key: keyof Omit<TAppPermissionListResponse['data'][number], 'id'> | 'action'
  value: string
}[] = [
  { key: 'name', value: 'PERMISSION NAME' },
  { key: 'module_name', value: 'MODULE NAME' },
  { key: 'created_at', value: 'CREATED AT' },
  { key: 'action', value: '' }
]

const AppPermissionList = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const {
    fetchAppPermissionList,
    fetchingAppPermissionList,
    permissions: { list, meta }
  } = useAppPermissionService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId) return
    fetchAppPermissionList({
      page,
      per_page: perPage,
      application_id: appId,
      q: debouncedSearchValue
    })
  }, [appId, fetchAppPermissionList, page, perPage, debouncedSearchValue])

  const handleOnClickAddNew = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSION_CREATE, { params })
  }, [params])

  const handleOnClickImport = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSIONS_CSV_IMPORT, { params })
  }, [params])

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[{ name: `${appName} Permissions` }]}
      />

      <article className="flex-1 flex flex-col gap-y-8 relative bg-[--colors-area-high] rounded-lg overflow-hidden">
        <header className="flex items-center gap-2 h-[56px] px-3 bg-[--colors-area-high] border-b border-[--colors-neutral-10]">
          <article className="flex flex-1 items-center gap-x-2 text-[--colors-text-light]">
            <RoleIcon />
            <p className="text-xl font-semibold">Permissions</p>
          </article>

          <Button
            styleVariants={{ kind: 'neutral', type: 'outlined' }}
            onClick={handleOnClickImport}
          >
            <Table2 size={14} />
            Import CSV File
          </Button>

          <Button onClick={handleOnClickAddNew}>
            <Icon namespace="Add" />
            Add New
          </Button>
        </header>

        <main className="xl:px-20 lg:px-10 px-5 flex flex-col gap-y-4 flex-1">
          <article className="flex flex-col gap-y-1">
            <p className="font-semibold">{appName}'s PERMISSIONS</p>
            <p className="text-xs text-[--colors-neutral-50]">
              Setup a mobile, web or IoT application to use CARROsso for
              Authentication.
            </p>
          </article>

          <article className="flex gap-x-2 items-center justify-between">
            <article>
              <BaseText
                affix={{
                  pre: <Icon namespace="Search" width={16} />,
                  post: searchValue ? (
                    <button onClick={() => updateSearchValue('')}>
                      <Icon namespace="Cross" width={18} />
                    </button>
                  ) : undefined
                }}
                inputProps={{
                  type: 'text',
                  value: searchValue,
                  onChange: e => updateSearchValue(e.currentTarget.value),
                  placeholder: 'Search here...'
                }}
              />
            </article>

            {!!meta?.total && (
              <p className="text-[--colors-text-disabled] text-sm font-medium">
                {meta.total} PERMISSION{meta.total > 1 && 'S'}
              </p>
            )}
          </article>

          <article className={customTableContainer}>
            <main className={tableContainerInner}>
              <table className={table({ separator: 'line' })}>
                <thead className={customTableHead}>
                  <tr className={tableRow}>
                    <th className={customTableCell} style={{ width: 0 }}>
                      <PermissionIcon />
                    </th>
                    {columns.map((col, key) => (
                      <th key={key} className={customTableCell}>
                        {col.value}
                      </th>
                    ))}
                  </tr>
                </thead>

                {!fetchingAppPermissionList && (
                  <tbody className={tableBody}>
                    {list.map((data, rowKey) => (
                      <tr key={rowKey} className={tableRow}>
                        <td className={customTableCell} style={{ width: 0 }}>
                          <PermissionIcon />
                        </td>
                        {columns.map((col, colKey) => (
                          <td key={colKey} className={customTableCell}>
                            {(() => {
                              if (col.key === 'name') {
                                return (
                                  <Link
                                    className="cursor-pointer hover:text-[--colors-primary-default] duration-200 font-semibold"
                                    to={ROUTE_NAMES.APP_PERMISSION_EDIT}
                                    options={{
                                      params: {
                                        ...params,
                                        permissionId: data.id
                                      }
                                    }}
                                  >
                                    <p>{data.name}</p>
                                  </Link>
                                )
                              }

                              if (col.key === 'created_at') {
                                return (
                                  <span className="uppercase">
                                    {data.created_at
                                      ? format(data.created_at, 'dd MMM yyyy')
                                      : 'N/A'}
                                  </span>
                                )
                              }

                              if (col.key === 'action') {
                                return (
                                  <button
                                    type="button"
                                    className="flex items-center text-[--colors-text-light] hover:text-[--colors-primary-default] duration-200"
                                  >
                                    <Icon
                                      namespace="MoreHorizontal"
                                      width={18}
                                    />
                                  </button>
                                )
                              }

                              return data[col.key] ?? 'N/A'
                            })()}
                          </td>
                        ))}
                      </tr>
                    ))}

                    {!list.length && (
                      <tr className={tableRow}>
                        <td
                          colSpan={columns.length}
                          style={{ textAlign: 'center' }}
                          className={customTableCell}
                        >
                          NO DATA
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </main>
          </article>

          {fetchingAppPermissionList && <Loading />}
        </main>

        {!!list.length && (
          <footer className="sticky bottom-0 bg-[--colors-area-high] p-4">
            <Pager align="right" {...pagerProps} />
          </footer>
        )}
      </article>
    </>
  )
}

export default AppPermissionList
