import CountryForm from '$blocks/Country/Form'
import {
  DEFAULT_FORM_VALUES,
  TFormValues
} from '$blocks/Country/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useCountryStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'

const CountryEdit = () => {
  const {
    route: {
      params: { countryId }
    }
  } = useRouteSummary()

  const countries = useCountryStore(state => state.countries)

  const defaultValues: TFormValues =
    countries.find(d => countryId && d.id === +countryId) ?? DEFAULT_FORM_VALUES

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.COUNTRIES}
        data={[{ name: defaultValues.name }]}
      />

      <CountryForm defaultValues={defaultValues} />
    </>
  )
}

export default CountryEdit
