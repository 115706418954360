import { cn } from '$app/utils'
import { TAppListResponse } from '$services/api'
import { APP_ENV_STATE_DATA } from './constants'

const AppEnvironment = ({
  environment
}: {
  environment: TAppListResponse['data'][number]['environment']
}) => {
  const { Icon, background, foreground } = APP_ENV_STATE_DATA[environment.key]

  return (
    <article className="flex gap-1 items-center">
      <p
        className={cn(
          'text-xs font-medium uppercase px-1.5 py-px rounded',
          background,
          foreground
        )}
      >
        {environment.value}
      </p>

      {Icon && (
        <article className={cn('inline-flex p-1 rounded', foreground)}>
          <Icon size={16} />
        </article>
      )}
    </article>
  )
}

export default AppEnvironment
