import { TAppHome } from '$services/api'
import { format } from 'date-fns'
import {
  ChevronRight,
  Clock,
  Expand,
  Inbox,
  LucideIcon,
  MapPin,
  Minimize2,
  Monitor,
  Smartphone,
  Tablet,
  User
} from 'lucide-react'
import { useCallback, useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { Button } from '@genie-fintech/ui/components'

const getDeviceIcon = (device: string): LucideIcon => {
  if (device.includes('desktop')) return Monitor
  if (device.includes('phone')) return Smartphone
  return Tablet
}

const EmptyData = () => {
  return (
    <article className="flex-1 flex flex-col items-center justify-center text-[--colors-text-disabled]">
      <Inbox size={20} />
      <p className="text-xs font-medium">There is no data to show</p>
    </article>
  )
}

const ExpandView = ({
  data
}: {
  data: TAppHome['recent_active_users'][number]
}) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false)
  }, [])

  return (
    <Dialog.Root open={dialogOpen}>
      <Button
        onClick={handleOpenDialog}
        styleVariants={{ kind: 'neutral', type: 'text' }}
      >
        <Expand size={18} />
      </Button>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content aria-describedby="" className="DialogContent max-w-xl">
          <Dialog.Title>
            <header className="flex items-center justify-between gap-2 sticky top-0 z-10 bg-[--colors-area-high] px-5 py-3 border-b border-[--colors-neutral-10]">
              <p className="font-semibold text-sm text-[--colors-text-light]">
                DEVICES LOG IN 30MINS
              </p>

              <Button
                onClick={handleCloseDialog}
                styleVariants={{ kind: 'neutral', type: 'text' }}
              >
                <Minimize2 size={18} />
              </Button>
            </header>
          </Dialog.Title>

          <article className="flex flex-col gap-2 px-5 py-3 max-h-[600px] overflow-y-auto">
            {!data.length && <EmptyData />}

            {data.map((v, k) => {
              const Icon = getDeviceIcon(v.device)

              return (
                <article
                  key={k}
                  className="flex flex-col gap-2 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg p-3"
                >
                  <article className="flex flex-1 gap-2">
                    <Icon size={20} className="text-[--colors-text-disabled]" />

                    <article className="flex-1 flex flex-col gap-1 items-start">
                      <p className="text-xs font-semibold text-[--colors-text-light] capitalize">
                        {`${v.device} - ${v.browser}`}
                      </p>
                      <p className="text-xs font-medium text-[--colors-text-disabled]">
                        {v.platform}
                      </p>
                    </article>
                  </article>

                  <hr />

                  <article className="flex flex-col gap-2 w-full">
                    <article className="flex items-center gap-1">
                      <User
                        size={16}
                        className="text-[--colors-text-disabled]"
                      />
                      <p className="flex-1 text-xs text-[--colors-neutral-50]">
                        Email
                      </p>
                      <p className="text-xs text-[--colors-neutral-50]">
                        {v.user_email}
                      </p>
                    </article>

                    <article className="flex items-center gap-1">
                      <Clock
                        size={16}
                        className="text-[--colors-text-disabled]"
                      />
                      <p className="flex-1 text-xs text-[--colors-neutral-50]">
                        Login Activity
                      </p>
                      <p className="text-xs text-[--colors-neutral-50]">
                        {format(v.created_at, 'dd MMM yyyy, hh:mm z')}
                      </p>
                    </article>

                    <article className="flex items-center gap-1">
                      <MapPin
                        size={16}
                        className="text-[--colors-text-disabled]"
                      />
                      <p className="flex-1 text-xs text-[--colors-neutral-50]">
                        Location
                      </p>
                      <p className="text-xs text-[--colors-neutral-50]">
                        {v.location}
                      </p>
                    </article>
                  </article>
                </article>
              )
            })}
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const LoginDevices = ({
  data
}: {
  data: TAppHome['recent_active_users'][number]
}) => {
  const [deviceId, setDeviceId] = useState('')

  return (
    <article className="flex max-h-[300px] overflow-y-auto flex-col px-5 relative bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[--colors-alphaNeutral-1] shadow-[0px_1px_2px_1px] rounded-lg">
      <header className="flex items-center justify-between gap-2 sticky top-0 z-[1] bg-[--colors-area-high] py-3">
        <p className="font-semibold text-sm text-[--colors-text-light]">
          DEVICES LOG IN 30MINS
        </p>

        {!!data.length && <ExpandView data={data} />}
      </header>
      <main className="flex-1 flex flex-col gap-4 pb-5">
        {!data.length && <EmptyData />}

        {data.map((v, k) => {
          const Icon = getDeviceIcon(v.device)
          return (
            <article
              key={k}
              className="overflow-hidden flex flex-col gap-2 w-full"
            >
              <header className="flex flex-1">
                <button
                  className="flex flex-1 gap-2"
                  onClick={() =>
                    setDeviceId(prev => (prev === v.id ? '' : v.id))
                  }
                >
                  <Icon size={20} className="text-[--colors-text-disabled]" />

                  <article className="flex-1 flex flex-col gap-1 items-start justify-start">
                    <p className="text-xs font-semibold text-[--colors-text-light] capitalize text-left">
                      {`${v.device} - ${v.browser}`}
                    </p>
                    <p className="text-xs font-medium text-[--colors-text-disabled]">
                      {v.platform}
                    </p>
                  </article>

                  <article className="flex h-full items-center">
                    <ChevronRight
                      data-active={deviceId === v.id}
                      className="text-[--colors-text-disabled] data-[active=true]:rotate-90 duration-200"
                      size={16}
                    />
                  </article>
                </button>
              </header>
              <main
                data-active={deviceId === v.id}
                className="flex h-0 data-[active=true]:h-[75px] transition-all duration-200"
              >
                <article className="flex flex-col gap-1 w-full">
                  <article className="flex items-center gap-1">
                    <User size={16} className="text-[--colors-text-disabled]" />
                    <p className="flex-1 text-xs text-[--colors-neutral-50]">
                      Email
                    </p>
                    <p className="text-xs text-[--colors-neutral-50]">
                      {v.user_email}
                    </p>
                  </article>

                  <article className="flex items-center gap-1">
                    <Clock
                      size={16}
                      className="text-[--colors-text-disabled]"
                    />
                    <p className="flex-1 text-xs text-[--colors-neutral-50]">
                      Login Activity
                    </p>
                    <p className="text-xs text-[--colors-neutral-50]">
                      {format(v.created_at, 'dd MMM yyyy, hh:mm z')}
                    </p>
                  </article>

                  <article className="flex items-center gap-1">
                    <MapPin
                      size={16}
                      className="text-[--colors-text-disabled]"
                    />
                    <p className="flex-1 text-xs text-[--colors-neutral-50]">
                      Location
                    </p>
                    <p className="text-xs text-[--colors-neutral-50]">
                      {v.location}
                    </p>
                  </article>
                </article>
              </main>
            </article>
          )
        })}
      </main>
    </article>
  )
}

export default LoginDevices
