import { redirect, ROUTE_NAMES } from '$app/router/config'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import { useMount } from 'ahooks'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import { Button } from '@genie-fintech/ui/components/Button'
import {
  loginSchema,
  TLoginFormValues,
  useLoginLogoutService
} from '$hooks/services'
import { Logo } from '$assets/svg'
import { Icon } from '@genie-fintech/ui/icons'

const Login = () => {
  const [showPwd, setShowPwd] = useState(false)

  const { token, user } = useAuthContext()

  const { login, loggingIn } = useLoginLogoutService()

  const { handleSubmit, control } = useForm<TLoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: '',
      password: ''
    }
  })

  useMount(() => {
    if (token && user) {
      redirect(ROUTE_NAMES.INDEX, { replace: true })
    }
  })

  const handleLogin = useCallback(
    (payload: TLoginFormValues) => {
      login(payload)
    },
    [login]
  )

  return (
    <article className="fixed inset-0 flex flex-col gap-y-2 items-center justify-between py-4 bg-[url('/gradient-bg.png')] bg-no-repeat bg-center bg-cover">
      <span className="absolute inset-0 bg-[url('/cover-noise.png')] bg-repeat bg-center bg-[100px_auto]" />

      <Logo />

      <form
        className="flex flex-col gap-y-4 py-10 px-16 rounded-xl bg-[--colors-neutral-0] max-w-[450px] shadow-[0px_12px_24px_12px] shadow-[--colors-alphaNeutral-1]"
        onSubmit={handleSubmit(handleLogin)}
      >
        <article className="flex flex-col gap-y-1">
          <p className="font-semibold text-[28px]">Login</p>

          <p>
            To access the CarroSSO service dashboard, please log in to the
            service.
          </p>
        </article>

        <BaseText
          label="Email"
          inputProps={{ type: 'email', autoComplete: 'email' }}
          name="email"
          control={control}
          required
        />

        <BaseText
          label="Password"
          inputProps={{
            type: showPwd ? 'text' : 'password',
            autoComplete: 'current-password'
          }}
          affix={{
            post: (
              <Icon
                namespace={showPwd ? 'EyeOff' : 'EyeOn'}
                onClick={() => {
                  setShowPwd(prev => !prev)
                }}
                className="cursor-pointer"
              />
            )
          }}
          name="password"
          control={control}
          required
        />

        <Button type="submit" disabled={loggingIn}>
          Login
        </Button>
      </form>

      <p className="text-xs text-[--colors-absolute-light]">
        ©2022 Genie Auth Service. All rights reserved.
      </p>
    </article>
  )
}

export default Login
