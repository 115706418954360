import { FC, PropsWithChildren } from 'react'
import { List as RadixList } from '@radix-ui/react-tabs'

import { propsWithClassNames } from '../../functions'

import { ListWrapperVariants, list, listWrapper } from './styles.css'
import { TagsNoRef } from '../../types'

export type ListProps = TagsNoRef<'div'> & {
  styleVariants?: ListWrapperVariants
}

export const List: FC<PropsWithChildren<ListProps>> = ({
  styleVariants,
  children,
  ...divProps
}) => (
  <div {...propsWithClassNames(divProps, listWrapper(styleVariants))}>
    <RadixList className={list} children={children} />
  </div>
)

export default List
