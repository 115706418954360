import { clamp, flatten } from 'lodash-es'
import { useMemo, useState } from 'react'

type Info = {
  text: string
  isActive: boolean
  isSeparator: boolean
  page?: number
}

const pagesToInfo = (pages: number[], currentPage: number): Info[] => {
  return pages.map(page => ({
    isActive: currentPage === page,
    text: `${page}`,
    isSeparator: false,
    page
  }))
}

type Hook = typeof usePager

export type UsePagerReturn = ReturnType<Hook>

export type UsePagerOptions = {
  total: number

  /** `default` or `initial` current page. */
  page: number

  /** Default: `10` */
  pageSize?: number

  /**
   * If `middlePageCount` is `2`, current page `3` with `5` pages,
   * and your middle pages will be `1,2` & `4,5`.
   *
   * Example: [ 1, 2, `3`, 4, 5, `...` ]
   */
  middlePageCount?: number

  /**
   * If `edgePageCount` is `2`, at least one side of
   * the separator will have `2` pages.
   *
   * Example: In [ 1, 2, `...`, 9, 10 ], `9` and `10` are edge pages.
   */
  edgePageCount?: number
}

export const usePager = ({
  total,
  page,
  pageSize = 10,
  middlePageCount = 0,
  edgePageCount = 0
}: UsePagerOptions) => {
  const currentPageSize = clamp(pageSize, 1, total)
  const pageCount = Math.max(1, Math.ceil(total) / currentPageSize)

  const toPage = (value: number) => clamp(value, 1, pageCount)

  const [currentPage, setCurrentPage] = useState(() => toPage(page))

  const pages = Array(Math.ceil(pageCount))
    .fill(0)
    .map((_, index) => index + 1)

  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === pageCount

  const hasPreviousPage = currentPage > 1
  const hasNextPage = currentPage < pageCount

  const isReachedToFirst = currentPage <= middlePageCount
  const isReachedToLast = currentPage + middlePageCount >= pageCount

  const middlePages = useMemo(() => {
    const _middlePageCount = middlePageCount * 2 + 1

    if (isReachedToFirst) return pages.slice(0, _middlePageCount)

    if (isReachedToLast) return pages.slice(_middlePageCount * -1)

    return pages.slice(
      currentPage - middlePageCount,
      currentPage + middlePageCount + 1
    )
  }, [currentPage, isReachedToFirst, isReachedToLast, pages, middlePageCount])

  const allPreviousPages = useMemo(
    () => pages.slice(0, middlePages[0] - 1),
    [pages, middlePages]
  )
  const allNextPages = useMemo(
    () => pages.slice(middlePages[middlePages.length - 1], pages[pages.length]),
    [pages, middlePages]
  )

  const previousPages = useMemo(() => {
    const isAtStart = isReachedToFirst || allPreviousPages.length < 1

    if (isAtStart) return []

    return pages.slice(0, edgePageCount).filter(p => !middlePages.includes(p))
  }, [allPreviousPages, edgePageCount, isReachedToFirst, pages, middlePages])

  const nextPages = useMemo(() => {
    const isAtEnd = isReachedToLast || allNextPages.length < 1

    if (isAtEnd) return []

    return pages
      .slice(pages.length - edgePageCount, pages.length)
      .filter(p => !middlePages.includes(p))
  }, [allNextPages, edgePageCount, isReachedToLast, pages, middlePages])

  const hasPrevPages = !!previousPages.length
  const hasMiddlePages = !!middlePages.length
  const hasNextPages = !!nextPages.length

  const hasPrevPageSeperator = hasPrevPages && hasMiddlePages
  const hasNextPageSeperator = hasNextPages && hasMiddlePages

  const info = useMemo(() => {
    const data: Info[][] = []

    const common: Info = {
      isActive: false,
      isSeparator: true,
      text: ''
    }

    data.push(pagesToInfo(previousPages, currentPage))

    if (hasPrevPageSeperator) data.push([{ ...common, text: 'break-p' }])

    data.push(pagesToInfo(middlePages, currentPage))

    if (hasNextPageSeperator) data.push([{ ...common, text: 'break-n' }])

    data.push(pagesToInfo(nextPages, currentPage))

    return flatten(data)
  }, [
    currentPage,
    hasNextPageSeperator,
    hasPrevPageSeperator,
    middlePages,
    nextPages,
    previousPages
  ])

  const toPrevious = () => {
    setCurrentPage(old => toPage(old - 1))
  }
  const toNext = () => {
    setCurrentPage(old => toPage(old + 1))
  }

  return {
    currentPage,
    currentPageSize,

    pageCount,
    pages,

    previousPages,
    nextPages,
    middlePages,

    info,

    hasPrevPageSeperator,
    hasNextPageSeperator,

    isFirstPage,
    isLastPage,

    hasPreviousPage,
    hasNextPage,

    toPrevious,
    toNext,
    jump: setCurrentPage
  }
}

export default usePager
