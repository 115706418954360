import { CopyButton } from '$components/CopyButon'
import { Button } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { generatePwd } from '$app/utils'
import { TAppUserDetail } from '$services/api'
import DeleteWithConfirm from '$components/DeleteWithConfirm'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { AlertTriangle, Clock } from 'lucide-react'
import { useAppUserService, usePasswordPolicyService } from '$hooks/services'
import PasswordStateMessage from '$components/PasswordStateMessage'
import { useMount } from 'ahooks'
import {
  LOWERCASE_UPPERCASE_NUMBER_REGEX,
  MIN_LENGTH_ID,
  PASSWORD_POLICY_CONFIG,
  SPECIAL_LETTER_REGEX,
  TWO_IDENTICAL_ROW_REGEX
} from '$pages/PasswordPolicy/constants'
import { Password } from '@genie-fintech/ui/components/fields'

enum VIEW {
  GENERATE = 'generate',
  CREATE_OWN = 'create_own'
}

const GeneratePassword = ({
  password_reset_link_expired_in,
  validPasswordPolicy,
  handleOnValidatePasswordPolicy
}: {
  password_reset_link_expired_in?: TAppUserDetail['password_reset_link_expired_in']
  validPasswordPolicy: boolean
  handleOnValidatePasswordPolicy: (value: boolean) => void
}) => {
  const {
    route: {
      params: { appId, userId }
    }
  } = useRouteSummary()

  const [view, setView] = useState<VIEW>(VIEW.GENERATE)

  const [showUpdateButton, setShowUpdateButton] = useState(!!userId ?? false)

  const [generatedUrl, setGeneratedUrl] = useState('')

  const [expiredIn, setExpiredIn] = useState(
    password_reset_link_expired_in ?? undefined
  )

  const { control, setValue } = useFormContext()

  const password: string = useWatch({ name: 'password', control })

  const {
    createResetPwdLinkAsync,
    creatingResetPwdLink,
    removeResetPwdLinkAsync,
    removingResetPwdLink
  } = useAppUserService()

  const { fetchPasswordPolicy, passwordPolicy } = usePasswordPolicyService()

  const isTimerStart = !!expiredIn || !!generatedUrl

  useEffect(() => {
    if (!expiredIn && generatedUrl) {
      setGeneratedUrl('')
    }
  }, [expiredIn, generatedUrl])

  useEffect(() => {
    if (!isTimerStart) {
      return
    }

    const timerInterval = setInterval(() => {
      setExpiredIn(prevTime => {
        if (prevTime === 0) {
          clearInterval(timerInterval)
          return 0
        } else {
          return prevTime ? prevTime - 1 : 0
        }
      })
    }, 1000)

    return () => clearInterval(timerInterval)
  }, [isTimerStart])

  useMount(fetchPasswordPolicy)

  const passwordPolicyState = useMemo(() => {
    return passwordPolicy
      .filter(d => d.is_enabled)
      .map(({ min_length, id }) => {
        const msg = (() => {
          let { label = '' } =
            PASSWORD_POLICY_CONFIG.find(d => d.id === id) ?? {}

          if (id === MIN_LENGTH_ID) {
            label = label.replace('###', `${min_length ?? '0'}`)
          }

          return label
        })()

        const isPass = (() => {
          switch (id) {
            case 1:
              return !!password.length && TWO_IDENTICAL_ROW_REGEX.test(password)
            case 2:
              return SPECIAL_LETTER_REGEX.test(password)
            case 3:
              return LOWERCASE_UPPERCASE_NUMBER_REGEX.test(password)
            case 4:
              return !!min_length && password.length >= +min_length
            case 5:
              return !!password.length
            default:
              return false
          }
        })()

        return { msg, isPass }
      })
  }, [passwordPolicy, password])

  useEffect(() => {
    if (!passwordPolicyState.length || !password.length) return

    if (!passwordPolicyState.every(d => d.isPass)) {
      handleOnValidatePasswordPolicy(false)
      return
    }

    handleOnValidatePasswordPolicy(true)
  }, [passwordPolicyState, password, handleOnValidatePasswordPolicy])

  const handleOnRegenerate = useCallback(() => {
    const pwd = generatePwd()
    setValue('password', pwd, { shouldDirty: true })
  }, [setValue])

  const handleOnUpdate = useCallback(() => {
    handleOnRegenerate()
    setShowUpdateButton(false)
  }, [handleOnRegenerate])

  const handleOnBack = useCallback(() => {
    setView(VIEW.GENERATE)
    handleOnRegenerate()
  }, [handleOnRegenerate])

  const handleOnCreateOwn = useCallback(() => {
    setView(VIEW.CREATE_OWN)
    setValue('password', '', { shouldDirty: true })
  }, [setValue])

  const handleOnCancel = useCallback(() => {
    setShowUpdateButton(true)
    setValue('password', '', { shouldDirty: true })
  }, [setValue])

  const onGenerate = useCallback(() => {
    if (!appId || !userId) return
    createResetPwdLinkAsync({ appId, userId }).then(({ data }) => {
      setGeneratedUrl(data.url)
      setExpiredIn(3599)
    })
  }, [createResetPwdLinkAsync, appId, userId])

  const onDelete = useCallback(() => {
    if (!appId || !userId) return
    removeResetPwdLinkAsync({ appId, userId }).then(() => {
      setGeneratedUrl('')
      setExpiredIn(undefined)
    })
  }, [removeResetPwdLinkAsync, appId, userId])

  return (
    <article className="grid lg:grid-cols-[40%_60%] bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
      <article className="flex flex-col gap-y-1">
        <p className="font-semibold text-[--colors-text-neutral]">PASSWORD</p>
        <p className="text-[--colors-neutral-50] text-xs">
          Setup a mobile, web or IoT application to use CARROsso for
          Authentication.
        </p>
      </article>

      <article className="flex flex-col gap-y-10 px-5 max-w-[500px]">
        {showUpdateButton && (
          <article className="flex flex-col">
            <Button
              onClick={handleOnUpdate}
              styleVariants={{ kind: 'neutral' }}
            >
              Update Password
            </Button>

            <p className="font-semibold text-[--colors-text-disabled] text-center pt-3">
              OR
            </p>

            {!expiredIn && (
              <Button
                styleVariants={{ type: 'text' }}
                onClick={onGenerate}
                disabled={creatingResetPwdLink}
              >
                Generate Reset Password Link
              </Button>
            )}

            {(!!expiredIn || generatedUrl) && (
              <>
                <article className="flex items-center gap-2 p-2 border border-[--colors-neutral-10] rounded-md shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1]">
                  <article className="flex flex-col flex-1 min-w-0 px-2">
                    <p className="text-xs font-medium text-[--colors-neutral-70]">
                      Generated Reset Password Link
                    </p>

                    {generatedUrl && (
                      <p className="text-[10px] text-[--colors-neutral-70] truncate min-w-0">
                        {generatedUrl}
                      </p>
                    )}
                  </article>

                  {generatedUrl && (
                    <CopyButton value={generatedUrl} size={20} />
                  )}

                  <DeleteWithConfirm
                    onConfirm={onDelete}
                    loading={removingResetPwdLink}
                  />
                </article>

                <article className="flex items-center justify-between p-2">
                  <article className="flex gap-1 ic">
                    <Clock size={16} />
                    {expiredIn && (
                      <p className="text-sm font-medium text-[--colors-text-light]">
                        {(() => {
                          const min = Math.floor(expiredIn / 60)
                          const sec = expiredIn % 60
                          return `${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`
                        })()}
                      </p>
                    )}
                  </article>

                  <article className="flex gap-1 items-center text-[--colors-warning-default] bg-[--colors-alphaWarning-1] px-3 py-1 rounded-[30px]">
                    <AlertTriangle size={16} />
                    <p className="text-xs font-medium">
                      Generated link has expiration time.
                    </p>
                  </article>
                </article>
              </>
            )}
          </article>
        )}

        {!showUpdateButton && (
          <>
            {view === VIEW.GENERATE && (
              <article className="flex flex-col gap-y-4">
                <article className="grid grid-cols-[1fr_auto] items-end gap-2">
                  <BaseText
                    inputProps={{
                      autoComplete: 'new-password',
                      readOnly: true
                    }}
                    required
                    label="System Generated Strong Password"
                    name="password"
                    control={control}
                  />

                  <article className="flex mb-2">
                    <CopyButton value={password} />
                  </article>

                  <Button onClick={handleOnRegenerate}>Regenerate</Button>

                  <span></span>

                  <Button
                    styleVariants={{ kind: 'neutral', type: 'outlined' }}
                    onClick={handleOnCreateOwn}
                  >
                    Create my own password
                  </Button>

                  <span></span>

                  {!!userId && (
                    <Button
                      styleVariants={{ kind: 'neutral', type: 'outlined' }}
                      onClick={handleOnCancel}
                    >
                      Cancel
                    </Button>
                  )}

                  <span></span>
                </article>
              </article>
            )}

            {view === VIEW.CREATE_OWN && (
              <article className="flex flex-col gap-y-4">
                <article className="grid grid-cols-[1fr_auto] items-end gap-2">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Password
                          inputProps={{
                            ...field,
                            autoComplete: 'new-password'
                          }}
                          error={!validPasswordPolicy}
                          required
                          label="Create Own Password"
                        />
                      )
                    }}
                  />

                  <article className="flex mb-2">
                    <CopyButton value={password} />
                  </article>

                  <Button
                    styleVariants={{ kind: 'neutral', type: 'outlined' }}
                    onClick={handleOnBack}
                  >
                    Back
                  </Button>

                  <span></span>
                </article>
              </article>
            )}
          </>
        )}

        {!showUpdateButton && (
          <article className="flex flex-col gap-y-1">
            {passwordPolicyState.map((v, k) => {
              return (
                <PasswordStateMessage
                  key={k}
                  message={v.msg}
                  isPass={v.isPass}
                />
              )
            })}
          </article>
        )}
      </article>
    </article>
  )
}

export default GeneratePassword
