import { Button, Spinner } from '@genie-fintech/ui/components'
import { Icon } from '@genie-fintech/ui/icons'
import * as Dialog from '@radix-ui/react-dialog'
import { useCallback, useState } from 'react'

interface IDeleteWithConfirmProps {
  onConfirm: VoidFunction
  loading?: boolean
  confirmOnDelete?: boolean
}

const DeleteWithConfirm = ({
  onConfirm,
  loading,
  confirmOnDelete = true
}: IDeleteWithConfirmProps) => {
  const [showConfirm, setShowConfirm] = useState(false)

  const handleOnClick = useCallback(() => {
    setShowConfirm(true)
  }, [])

  const handleOnCancel = useCallback(() => {
    setShowConfirm(false)
  }, [])

  const handleOnConfirm = useCallback(() => {
    setShowConfirm(false)
    onConfirm()
  }, [onConfirm])

  return (
    <Dialog.Root open={showConfirm}>
      <Button
        styleVariants={{
          kind: 'danger',
          category: 'icon',
          type: 'text'
        }}
        onClick={confirmOnDelete ? handleOnClick : onConfirm}
      >
        {loading ? <Spinner /> : <Icon namespace="Trash" />}
      </Button>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          className="DialogContent max-w-[500px]"
          aria-describedby=""
        >
          <article className="flex flex-col p-5 gap-y-2 min-w-[300px]">
            <Dialog.Title>
              <header className="text-xl font-semibold">Are you sure?</header>
            </Dialog.Title>

            <p className="text-[--colors-neutral-50] text-sm pb-2">
              This action can't be undone.
            </p>

            <footer className="flex gap-x-2 items-center justify-end border-t border-[--colors-neutral-10] pt-2">
              <Button
                styleVariants={{ type: 'outlined' }}
                onClick={handleOnCancel}
              >
                Cancel
              </Button>

              <Button
                styleVariants={{ kind: 'danger' }}
                onClick={handleOnConfirm}
              >
                Delete
              </Button>
            </footer>
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default DeleteWithConfirm
