import { TAppHome } from '$services/api'
import { themeVars } from '@genie-fintech/ui/style/theme'
import { format } from 'date-fns'

const { colors } = themeVars

export const getLast30minsOption = (data: TAppHome['recent_active_users']) => ({
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: 'Last 30mins Active User',
      data: data.map(v => v.length),
      dataLabels: {
        enabled: false // Hides the data labels
      },
      color: colors.alphaPrimary[3], // Custom color for the data bars
      borderWidth: 0, // Removes the border
      states: {
        hover: {
          color: colors.primary[70] // Custom hover color
        }
      }
    }
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
          }
        }
      }
    ]
  },
  xAxis: {
    categories: [
      'Last 0-5 mins',
      'Last 5-10 mins',
      'Last 10-15 mins',
      'Last 15-20 mins',
      'Last 20-25 mins',
      'Last 25-30 mins'
    ],
    visible: true, // Ensures the x-axis line is visible
    labels: {
      enabled: false // Hides the labels on the x-axis
    },
    lineColor: colors.area.disabled
  },
  plotOptions: {
    column: {
      pointPadding: 0.02, // Adjusts the padding between bars
      groupPadding: 0 // Adjusts the padding between groups of bars
    }
  },
  chart: {
    type: 'column',
    height: 250,
    backgroundColor: colors.area.high
  },
  yAxis: {
    title: {
      text: ''
    },
    gridLineWidth: 0,
    labels: {
      enabled: false
    },
    visible: false
  },
  legend: {
    enabled: false // Hides the legend
  },
  accessibility: {
    enabled: false
  }
})

export const getDailyActiveUserOption = (data: TAppHome['active_users']) => ({
  title: {
    text: ''
  },
  credits: {
    enabled: false
  },
  series: [
    {
      name: 'Daily Active User',
      data: data.map(v => v.count),
      color: colors.primary[70],
      fillColor: colors.alphaPrimary[3]
    }
  ],
  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'middle'
          }
        }
      }
    ]
  },
  xAxis: {
    categories: data.map(v => format(v.date, 'E dd')),
    labels: {
      useHtml: true,
      formatter: ({ value }: { value: string }) => {
        return value.split(' ').join('<br>')
      },
      style: {
        color: colors.text.light
      }
    },
    tickmarkPlacement: 'on',
    plotLines: data.map((_, k) => ({
      dashStyle: 'Dash',
      color: colors.neutral[40],
      width: 1,
      value: k
    }))
  },
  chart: {
    type: 'area',
    backgroundColor: colors.area.high
  },
  yAxis: {
    title: {
      text: ''
    },
    gridLineWidth: 0,
    labels: {
      enabled: false
    }
  },
  legend: {
    enabled: false
  },
  accessibility: {
    enabled: false
  },
  // Adding the noData module
  noData: {
    style: {
      fontWeight: 'bold',
      color: '#303030'
    },
    useHTML: true,
    position: {
      align: 'center',
      verticalAlign: 'middle',
      x: 0,
      y: 0
    }
  }
})
