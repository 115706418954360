import useDarkMode from './useDarkMode'

import { darkTheme, lightTheme } from '../style/theme'

export const useTheme = () => {
  const {
    toggle: toggleTheme,
    isDarkMode,
    isAuto,
    rotate: rotateTheme
  } = useDarkMode()

  const className = isDarkMode ? darkTheme : lightTheme

  return {
    toggleTheme,
    rotateTheme,
    className,
    mode: {
      isDarkMode,
      isAuto
    }
  }
}

export default useTheme
