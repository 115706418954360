import { getAppHome } from '$services/api'
import { useRequest } from 'ahooks'

export const useAppHomeService = () => {
  const {
    run: fetchAppHome,
    loading: fetchingAppHome,
    data: appHomeData
  } = useRequest(getAppHome, { manual: true })

  const { data: appHome } = appHomeData ?? {}

  return {
    fetchAppHome,
    fetchingAppHome,
    appHome
  }
}
