import AppGroupForm from '$app/blocks/AppGroup/Form'
import {
  DEFAULT_FORM_VALUES,
  TFormValues
} from '$blocks/AppGroup/Form/constants'
import AppGroupRoleList from '$blocks/AppGroup/RoleList'
import AppGroupUserList from '$blocks/AppGroup/UserList'
import Breadcrumb from '$components/Breadcrumb'
import DeleteWithConfirmInput from '$components/DeleteWithConfirmInput'
import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useAppGroupService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'
import { useMount } from 'ahooks'
import { useMemo } from 'react'

const AppGroupEdit = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  const {
    route: { params }
  } = useRouteSummary()

  const { groupId, appId } = params

  const { group, fetchAppGroupDetail, fetchingAppGroupDetail } =
    useAppGroupService()

  useMount(() => {
    if (!appId || !groupId) return
    fetchAppGroupDetail({ application_id: appId, group_id: groupId })
  })

  const defaultValues = useMemo(() => {
    if (!group) return DEFAULT_FORM_VALUES

    const { country, phone_code, ...rest } = group

    const modifiedData: TFormValues = {
      country: `${country.id}`,
      phone_code: { label: phone_code, value: phone_code },
      ...rest
    }

    return modifiedData
  }, [group])

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[
          { name: `${appName} Groups`, path: ROUTE_NAMES.APP_GROUPS },
          { name: `${group?.name ?? ''}` }
        ]}
      />

      {fetchingAppGroupDetail && <Loading />}

      {!fetchingAppGroupDetail && (
        <article className="flex flex-col gap-y-4">
          <AppGroupForm appName={appName} defaultValues={defaultValues} />

          <AppGroupRoleList groupName={group?.name ?? ''} />

          <AppGroupUserList groupName={group?.name ?? ''} />

          <DeleteWithConfirmInput type="group" name={defaultValues.name} />
        </article>
      )}
    </>
  )
}

export default AppGroupEdit
