import { redirect, ROUTE_NAMES } from '$app/router/config'
import { postLogin, postLogout } from '$app/services/api'
import { useAuthContext } from '$contexts/AuthContext/hooks'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'
import { z } from 'zod'

export const loginSchema = z.object({
  email: z.string().trim().min(1, { message: 'Required!' }).email(),
  password: z.string().trim().min(1, 'Requried!')
})

export type TLoginFormValues = z.infer<typeof loginSchema>

export const useLoginLogoutService = () => {
  const { updateToken } = useAuthContext()

  const { loading: loggingIn, run: login } = useRequest(postLogin, {
    manual: true,
    onSuccess: ({ data: { name } }) => {
      redirect(ROUTE_NAMES.INDEX, { replace: true })
      toast.success(`Welcome ${name}!\nYou're all set to explore.`)
    },
    onFinally: (_, data) => {
      updateToken(data?.token)
    }
  })

  const { loading: loggingOut, run: logout } = useRequest(postLogout, {
    manual: true,
    onSuccess: () => {
      updateToken(undefined)
      toast.success(`You've logged out!`)
    }
  })

  return {
    loggingIn,
    login,

    loggingOut,
    logout
  }
}
