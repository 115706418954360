import { usePager } from '@genie-fintech/ui/hooks'
import { useDebounceFn } from 'ahooks'
import { useCallback, useEffect, useState } from 'react'

type TProps = {
  initialSearchValue?: string
  perPage?: number
  total?: number
}

export const PER_PAGE = 15

export const useApiListingParams = (props?: TProps) => {
  const { initialSearchValue = '', perPage = PER_PAGE, total = 1 } = props ?? {}

  const [searchValue, setSearchValue] = useState(initialSearchValue)

  const [debouncedSearchValue, setDebouncedSearchValue] =
    useState(initialSearchValue)

  const pagerProps = usePager({
    page: 1,
    pageSize: perPage,
    total,
    edgePageCount: 1,
    middlePageCount: 2
  })

  const { currentPage, jump } = pagerProps

  const { run } = useDebounceFn(
    (value: string) => {
      setDebouncedSearchValue(value)
      if (currentPage !== 1) jump(1)
    },
    { wait: 500 }
  )

  useEffect(() => {
    run(searchValue)
  }, [run, searchValue])

  const updateSearchValue = useCallback((value: string) => {
    setSearchValue(value)
  }, [])

  return {
    searchValue,
    debouncedSearchValue,
    updateSearchValue,
    pagerProps,
    perPage
  }
}
