import { cn } from '$app/utils'

const ActiveInactiveStatusBadge = ({
  status
}: {
  status: 'active' | 'inactive'
}) => {
  return (
    <span
      className={cn(
        'inline-flex items-center px-1.5 py-px rounded uppercase text-xs font-medium',
        status === 'active' &&
          'bg-[--colors-alphaSuccess-1] text-[--colors-success-default]',
        status === 'inactive' &&
          'bg-[--colors-alphaDanger-1] text-[--colors-danger-default]'
      )}
    >
      {status}
    </span>
  )
}

export default ActiveInactiveStatusBadge
