export const BasicSettingIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 3.24994C5.5 2.83573 5.83579 2.49994 6.25 2.49994H13C13.4142 2.49994 13.75 2.83573 13.75 3.24994C13.75 3.66415 13.4142 3.99994 13 3.99994H6.25C5.83579 3.99994 5.5 3.66415 5.5 3.24994Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 10.7499C1 10.3357 1.33579 9.99994 1.75 9.99994H8.5C8.91421 9.99994 9.25 10.3357 9.25 10.7499C9.25 11.1642 8.91421 11.4999 8.5 11.4999H1.75C1.33579 11.4999 1 11.1642 1 10.7499Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.75 9.24994C9.92157 9.24994 9.25 9.92151 9.25 10.7499C9.25 11.5784 9.92157 12.2499 10.75 12.2499C11.5784 12.2499 12.25 11.5784 12.25 10.7499C12.25 9.92151 11.5784 9.24994 10.75 9.24994ZM7.75 10.7499C7.75 9.09308 9.09315 7.74994 10.75 7.74994C12.4069 7.74994 13.75 9.09308 13.75 10.7499C13.75 12.4068 12.4069 13.7499 10.75 13.7499C9.09315 13.7499 7.75 12.4068 7.75 10.7499Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 1.74994C2.42157 1.74994 1.75 2.42151 1.75 3.24994C1.75 4.07837 2.42157 4.74994 3.25 4.74994C4.07843 4.74994 4.75 4.07837 4.75 3.24994C4.75 2.42151 4.07843 1.74994 3.25 1.74994ZM0.25 3.24994C0.25 1.59308 1.59315 0.249939 3.25 0.249939C4.90685 0.249939 6.25 1.59308 6.25 3.24994C6.25 4.90679 4.90685 6.24994 3.25 6.24994C1.59315 6.24994 0.25 4.90679 0.25 3.24994Z"
        fill="currentColor"
      />
    </svg>
  )
}
