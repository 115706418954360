import {
  errorMessageResolver,
  getAppAdvancedSetting,
  putAppAdvancedSetting
} from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppAdvancedSettingService = () => {
  const {
    run: fetchAppAdvancedSetting,
    runAsync: fetchAppAdvancedSettingAsync,
    loading: fetchingAppAdvancedSetting
  } = useRequest(getAppAdvancedSetting, {
    manual: true
  })

  const {
    run: updateAppAdvancedSetting,
    runAsync: updateAppAdvancedSettingAsync,
    loading: updatingAppAdvancedSetting
  } = useRequest(putAppAdvancedSetting, {
    manual: true,
    onSuccess: () => {
      toast.success('Successfully Updated!')
    },
    onError: err => {
      toast.error(errorMessageResolver(err))
    }
  })

  return {
    fetchAppAdvancedSetting,
    fetchAppAdvancedSettingAsync,
    fetchingAppAdvancedSetting,

    updateAppAdvancedSetting,
    updateAppAdvancedSettingAsync,
    updatingAppAdvancedSetting
  }
}
