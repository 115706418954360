import { FormProvider, useForm } from 'react-hook-form'
import { schema, TFormValues } from './constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback } from 'react'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import { TAppPermissionDetail, TAppPermissionPayload } from '$services/api'
import { useAppPermissionService } from '$hooks/services'
import { redirect, ROUTE_NAMES } from '$router/config'
import AppPermissionRoleList from '../RoleList'
import { Icon } from '@genie-fintech/ui/icons'
import DeleteWithConfirmInput from '$components/DeleteWithConfirmInput'

const descriptionText = `Setup a mobile, web or IoT application to use CARROsso for Authentication.`

interface IAppPermissionFormProps {
  defaultValues: TFormValues
  appName: string
  roles?: TAppPermissionDetail['roles']
}

const AppPermissionForm = ({
  defaultValues,
  appName,
  roles
}: IAppPermissionFormProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, permissionId } = params

  const {
    savePermissionAsync,
    savingPermission,
    updatePermissionAsync,
    updatingPermission
  } = useAppPermissionService()

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty }
  } = methods

  const handleOnRedirect = useCallback(() => {
    redirect(ROUTE_NAMES.APP_PERMISSIONS, { params })
  }, [params])

  const handleOnCancel = useCallback(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const onSubmit = useCallback(
    (values: TFormValues) => {
      if (!appId) return

      const payload: TAppPermissionPayload = values

      if (permissionId) {
        updatePermissionAsync(appId, permissionId, payload).then(
          handleOnRedirect
        )
        return
      }

      savePermissionAsync(appId, payload).then(handleOnRedirect)
    },
    [
      appId,
      permissionId,
      updatePermissionAsync,
      handleOnRedirect,
      savePermissionAsync
    ]
  )

  const title = (() => {
    if (permissionId) return `Edit ${defaultValues.name}`
    return `Add New Permission for ${appName}`
  })()

  const isProcessing = savingPermission || updatingPermission

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-y-4 flex-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <header className="flex items-center gap-2 justify-between">
          <p className="text-xl font-semibold text-[--colors-text-light]">
            {title}
          </p>

          <article className="flex items-center gap-x-2">
            {permissionId && (
              <SaveWithShortCutButton
                disabled={!isDirty || isProcessing}
                loading={isProcessing}
              />
            )}

            {!permissionId && (
              <>
                <Button
                  disabled={isProcessing}
                  styleVariants={{ type: 'outlined', kind: 'neutral' }}
                  onClick={handleOnCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isDirty || isProcessing}
                  type="submit"
                  className="!px-4"
                >
                  {isProcessing && <Spinner />}
                  Add
                </Button>
              </>
            )}
          </article>
        </header>
        <main className="flex-1 flex flex-col gap-y-4">
          {!!roles?.length && (
            <article className="grid lg:grid-cols-[40%_60%] bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
              <article className="flex flex-col gap-y-1">
                <p className="font-semibold text-[--colors-text-neutral]">
                  ROLES FOR THIS PERMISSION
                </p>
                <p className="text-[--colors-neutral-50] text-xs">
                  {descriptionText}
                </p>
              </article>

              <article className="flex flex-col gap-y-4 px-5 max-w-[500px]">
                {roles?.map((v, k) => {
                  return (
                    <article
                      key={k}
                      className="flex items-start gap-x-2 rounded-lg px-3 py-2.5 bg-[--colors-alphaNeutral-0] border border-[--colors-neutral-10]"
                    >
                      <Icon namespace="User" />

                      <article className="flex flex-col gap-y-px">
                        <article className="font-medium text-sm text-[--colors-text-light]">
                          {v.name}
                        </article>
                        <article className="flex items-center">
                          <span className="inline-flex items-center rounded bg-[--colors-alphaTertiary-1] text-[--colors-tertiary-default] px-1.5 text-xs">
                            {v.country.country_code}
                          </span>
                        </article>
                      </article>
                    </article>
                  )
                })}
              </article>
            </article>
          )}

          <article className="grid lg:grid-cols-[40%_60%] bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
            <article className="flex flex-col gap-y-1">
              <p className="font-semibold text-[--colors-text-neutral]">
                PERMISSION INFO
              </p>
              <p className="text-[--colors-neutral-50] text-xs">
                {descriptionText}
              </p>
            </article>

            <article className="flex flex-col gap-y-4 px-5 max-w-[500px]">
              <BaseText
                control={control}
                name="name"
                label="Permission Name"
                required
              />

              <BaseText
                control={control}
                name="module_name"
                label="Module Name"
                required
              />

              <Textarea
                name="description"
                control={control}
                label="Description"
                required
              />
            </article>
          </article>

          <AppPermissionRoleList appName={appName} />

          {permissionId && (
            <DeleteWithConfirmInput
              type="permission"
              name={defaultValues.name}
            />
          )}
        </main>
      </form>
    </FormProvider>
  )
}

export default AppPermissionForm
