import { create } from 'zustand'

interface IAppScopeState {
  appScopes: {
    value: string
    label: string
  }[]
  updateAppScopes: (appScopes: IAppScopeState['appScopes']) => void
}

export const useAppScopeStore = create<IAppScopeState>()(set => ({
  appScopes: [],
  updateAppScopes: appScopes => set({ appScopes })
}))
