import { TAppListResponse } from '$services/api'
import { create } from 'zustand'

interface IAppDetailState {
  appDetail?: Omit<
    TAppListResponse['data'][number],
    'group_count' | 'role_count' | 'user_count'
  >
  updateAppDetail: (appDetail: IAppDetailState['appDetail']) => void
}

export const useAppDetailStore = create<IAppDetailState>()(set => ({
  appDetail: undefined,
  updateAppDetail: appDetail => set({ appDetail })
}))
