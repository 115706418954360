import { BREADCRUMB_BAR_ID } from '$app/constants'
import { cn } from '$app/utils'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { ROUTE_NAMES, RouteNames } from '$router/config'
import Link from '$router/Link'
import { Icon } from '@genie-fintech/ui/icons'
import { createPortal } from 'react-dom'
import { Fragment } from 'react/jsx-runtime'

interface IBreadcrumbProps {
  mainPage: ROUTE_NAMES
  data: { name: string; path?: RouteNames }[]
  disabled?: boolean
  renderAsChild?: boolean
}

const Breadcrumb = ({
  mainPage,
  data,
  disabled,
  renderAsChild
}: IBreadcrumbProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const renderBreadcrumbOffset = () => {
    return (
      <article className="flex items-center gap-x-2 relative">
        <Link
          to={mainPage}
          options={{ params }}
          className={cn(
            'inline-flex gap-x-2 items-center cursor-pointer',
            disabled && 'pointer-events-none'
          )}
        >
          <Icon namespace="Backward" width={16} />

          <span className="font-medium text-sm text-[--colors-neutral-50] hover:text-[--colors-text-light] duration-200">
            {(() => {
              switch (mainPage) {
                case ROUTE_NAMES.APPS:
                  return 'Applications'
                case ROUTE_NAMES.COUNTRIES:
                  return 'Countries listing'
                default:
                  return null
              }
            })()}
          </span>
        </Link>

        {data.map((v, k) => {
          return (
            <Fragment key={k}>
              <span className="font-medium text-xs text-[--colors-neutral-50]">
                /
              </span>

              {v.path ? (
                <Link
                  to={v.path}
                  options={{ params }}
                  className={disabled ? 'pointer-events-none' : undefined}
                >
                  <span className="font-medium text-sm text-[--colors-neutral-50] cursor-pointer hover:text-[--colors-text-light] duration-200">
                    {v.name}
                  </span>

                  <span className="sr-only">{/* LABEL  */}</span>
                </Link>
              ) : (
                <span className="text-sm font-semibold text-[--colors-text-light]">
                  {v.name}
                </span>
              )}
            </Fragment>
          )
        })}
      </article>
    )
  }

  if (renderAsChild) return renderBreadcrumbOffset()

  const targetContainer = document.getElementById(BREADCRUMB_BAR_ID)

  if (!targetContainer) return null

  return createPortal(renderBreadcrumbOffset(), targetContainer)
}

export default Breadcrumb
