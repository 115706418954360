import { getAppList, getAppSelectList } from '$services/api'
import { useRequest } from 'ahooks'

export const useAppService = () => {
  const {
    run: fetchAppList,
    runAsync: fetchAppListAsync,
    data: AppListData,
    loading: fetchingAppList
  } = useRequest(getAppList, {
    manual: true
  })

  const {
    run: fetchAppSelectList,
    runAsync: fetchAppSelectListAsync,
    data: AppSelectListData,
    loading: fetchingAppSelectList
  } = useRequest(getAppSelectList, {
    manual: true
  })

  const { data: list = [], meta } = { ...AppListData }

  const { data: app_option_list = [] } = { ...AppSelectListData }

  return {
    app: { list, meta },
    fetchAppList,
    fetchingAppList,
    fetchAppListAsync,

    app_option_list,
    fetchAppSelectList,
    fetchAppSelectListAsync,
    fetchingAppSelectList
  }
}
