import { useMount } from 'ahooks'
import { useState } from 'react'

type OS = 'mac' | 'windows' | 'linux' | ''

export const useOS = () => {
  const [os, setOs] = useState<OS>('')

  useMount(() => {
    // Detect OS
    const userAgent = window.navigator.userAgent

    if (userAgent.includes('Mac')) {
      setOs('mac')
      return
    }

    if (userAgent.includes('Windows')) {
      setOs('windows')
      return
    }

    if (userAgent.includes('Linux')) {
      setOs('linux')
      return
    }
  })

  return {
    os
  }
}
