export const HomeIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53955 0.907955C7.81038 0.697308 8.18962 0.697308 8.46045 0.907955L15.2105 6.15796C15.3931 6.30005 15.5 6.51853 15.5 6.74997V15C15.5 15.5967 15.2629 16.169 14.841 16.591C14.419 17.0129 13.8467 17.25 13.25 17.25H2.75C2.15326 17.25 1.58097 17.0129 1.15901 16.591C0.737053 16.169 0.5 15.5967 0.5 15V6.74997C0.5 6.51853 0.606855 6.30005 0.789545 6.15796L7.53955 0.907955ZM2 7.11678V15C2 15.1989 2.07902 15.3896 2.21967 15.5303C2.36032 15.671 2.55109 15.75 2.75 15.75H13.25C13.4489 15.75 13.6397 15.671 13.7803 15.5303C13.921 15.3896 14 15.1989 14 15V7.11678L8 2.45012L2 7.11678Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 8.99997C5 8.58576 5.33579 8.24997 5.75 8.24997H10.25C10.6642 8.24997 11 8.58576 11 8.99997V16.5C11 16.9142 10.6642 17.25 10.25 17.25C9.83579 17.25 9.5 16.9142 9.5 16.5V9.74997H6.5V16.5C6.5 16.9142 6.16421 17.25 5.75 17.25C5.33579 17.25 5 16.9142 5 16.5V8.99997Z"
        fill="currentColor"
      />
    </svg>
  )
}
