import { cn } from '$app/utils'
import { Icon } from '@genie-fintech/ui/icons'

const PasswordStateMessage = ({
  message,
  isPass
}: {
  message: string
  isPass: boolean
}) => {
  return (
    <article className="flex items-center gap-x-2">
      <Icon
        namespace={isPass ? 'TickCircle' : 'Warning'}
        width={16}
        color={isPass ? 'success.default' : 'text.light'}
      />

      <span
        className={cn(
          'text-xs',
          isPass
            ? 'text-[--colors-text-neutral]'
            : 'text-[--colors-text-disabled]'
        )}
      >
        {message}
      </span>
    </article>
  )
}

export default PasswordStateMessage
