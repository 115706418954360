import { TCountry } from '$services/api'
import { create } from 'zustand'

interface IOption {
  label: string
  value: string
}

interface ICountryState {
  countries: TCountry[]
  countryOptions: IOption[]
  phoneOptions: IOption[]

  updateCountries: (countries: ICountryState['countries']) => void
}

const getOptions = (countries: TCountry[]) => {
  const countryOptions = countries.map(v => ({
    label: v.name,
    value: `${v.id}`
  }))

  const phoneOptions = countries.map(v => ({
    label: v.phone_code,
    value: v.phone_code
  }))

  return { countryOptions, phoneOptions }
}

export const useCountryStore = create<ICountryState>()(set => ({
  countries: [],
  countryOptions: [],
  phoneOptions: [],

  updateCountries: countries =>
    set(() => {
      const { countryOptions, phoneOptions } = getOptions(countries)
      return { countries, countryOptions, phoneOptions }
    })

  // updateCountry: (country) => set((state) => {
  //     const newCountries = [...state.countries, country]
  //     const { countryOptions, phoneOptions } = getOptions(newCountries)
  //     return ({ countries: newCountries, countryOptions, phoneOptions })
  // }),
}))
