import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams } from '$hooks/actions'
import { useAppGroupService } from '$hooks/services'
import { ROUTE_NAMES } from '$router/config'
import Link from '$router/Link'
import { TAppGroupRoleResponse } from '$services/api'
import {
  customTableCell,
  customTableContainer,
  customTableHead
} from '$styles/common.css'
import { Button, Pager } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import {
  table,
  tableBody,
  tableContainerInner,
  tableRow
} from '@genie-fintech/ui/style/element'
import { useEffect } from 'react'

const columns: {
  key: keyof Omit<TAppGroupRoleResponse['data'][number], 'id'> | 'action'
  value: string
}[] = [
  { key: 'name', value: 'ROLE NAME' },
  { key: 'permission_count', value: 'PERMISSIONS' },
  { key: 'action', value: '' }
]

const AppGroupRoleList = ({ groupName }: { groupName: string }) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, groupId } = params

  const {
    fetchAppGroupRole,
    fetchingAppGroupRole,
    groupRoles: { list, meta }
  } = useAppGroupService()

  const {
    searchValue,
    updateSearchValue,
    debouncedSearchValue,
    pagerProps,
    perPage
  } = useApiListingParams({ total: meta?.total, perPage: 10 })

  const { currentPage: page } = pagerProps

  useEffect(() => {
    if (!appId || !groupId) return
    fetchAppGroupRole({
      page,
      per_page: perPage,
      application_id: appId,
      group_id: groupId,
      q: debouncedSearchValue
    })
  }, [appId, groupId, fetchAppGroupRole, page, perPage, debouncedSearchValue])

  return (
    <article className="flex flex-col gap-y-2 bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
      <article className="flex flex-col gap-y-1">
        <p className="font-semibold">{groupName}'s ROLES</p>
        <p className="text-xs text-[--colors-neutral-50]">
          Setup a mobile, web or IoT application to use CARROsso for
          Authentication.
        </p>
      </article>

      <article className="flex gap-x-2 items-center justify-between">
        <article>
          <BaseText
            affix={{
              pre: <Icon namespace="Search" width={16} />,
              post: searchValue ? (
                <button onClick={() => updateSearchValue('')}>
                  <Icon namespace="Cross" width={18} />
                </button>
              ) : undefined
            }}
            inputProps={{
              type: 'text',
              value: searchValue,
              onChange: e => updateSearchValue(e.currentTarget.value),
              placeholder: 'Search here...'
            }}
          />
        </article>

        {!!meta?.total && (
          <p className="text-[--colors-text-disabled] text-sm font-medium">
            {meta.total} ROLE{meta.total > 1 && 'S'}
          </p>
        )}
      </article>

      <article className={customTableContainer}>
        <main className={tableContainerInner}>
          <table className={table({ separator: 'line' })}>
            <thead className={customTableHead}>
              <tr className={tableRow}>
                <th className={customTableCell} style={{ width: 0 }}>
                  <Icon namespace="User" color="text.light" width={18} />
                </th>
                {columns.map((col, key) => (
                  <th key={key} className={customTableCell}>
                    {col.value}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className={tableBody}>
              {list.map((data, rowKey) => (
                <tr key={rowKey} className={tableRow}>
                  <td className={customTableCell}>
                    <Icon namespace="User" color="text.light" width={18} />
                  </td>
                  {columns.map((col, colKey) => (
                    <td key={colKey} className={customTableCell}>
                      {(() => {
                        if (col.key === 'action') {
                          return (
                            <article>
                              <Link
                                to={ROUTE_NAMES.APP_ROLE_EDIT}
                                options={{
                                  params: { ...params, roleId: data.id }
                                }}
                                className="relative"
                              >
                                <Button
                                  styleVariants={{
                                    type: 'text',
                                    category: 'icon',
                                    kind: 'neutral'
                                  }}
                                >
                                  <span className="-rotate-45">
                                    <Icon namespace="Forward" width={18} />
                                  </span>
                                </Button>

                                <span className="sr-only">Role Detail</span>
                              </Link>
                            </article>
                          )
                        }

                        return data[col.key] ?? 'N/A'
                      })()}
                    </td>
                  ))}
                </tr>
              ))}

              {!list.length && (
                <tr className={tableRow}>
                  <td
                    colSpan={columns.length}
                    style={{ textAlign: 'center' }}
                    className={customTableCell}
                  >
                    NO DATA
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {fetchingAppGroupRole && <Loading />}
        </main>
      </article>

      {!!list.length && (
        <footer className="flex justify-end bg-[--colors-area-high] p-4">
          <Pager align="right" {...pagerProps} />
        </footer>
      )}
    </article>
  )
}

export default AppGroupRoleList
