import { FC, PropsWithChildren } from 'react'
import Sidebar from './Sidebar'
import Loading from '$components/Loading'
import { useIsMounted } from '@genie-fintech/ui/hooks'
import { BREADCRUMB_BAR_ID } from '$app/constants'

const GlobalSettingLayout: FC<PropsWithChildren> = ({ children }) => {
  const isMounted = useIsMounted()

  if (!isMounted) return <Loading />

  return (
    <section className="flex flex-col gap-y-2 flex-1">
      <article
        id={BREADCRUMB_BAR_ID}
        className="flex items-center ml-[236px] px-4 h-[40px]"
      >
        {/* Breadcrumb bar  */}
      </article>

      <article className="flex-1 flex">
        <Sidebar />

        <article className="flex-1 flex flex-col ml-[236px] px-4">
          {children}
        </article>
      </article>
    </section>
  )
}

export default GlobalSettingLayout
