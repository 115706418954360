import { Controller, FormProvider, useForm } from 'react-hook-form'
import { schema, TFormValues } from './constants'
import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useState } from 'react'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import SaveWithShortCutButton from '$components/SaveWithShortCutButton'
import { Avatar, Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import Select from '$components/Select'
import { GENDER_OPTIONS } from '$app/constants'
import { useAppUserService } from '$hooks/services'
import Loading from '$components/Loading'
import Textarea from '@genie-fintech/ui/components/hook-fields/Textarea'
import AppUserRoleList from '../RoleList'
import GeneratePassword from '../GeneratePassword'
import { TAppUserDetail, TAppUserPayload } from '$services/api'
import { format } from 'date-fns'
import { redirect, ROUTE_NAMES } from '$router/config'
import ActiveInactiveStatusBadge from '$components/ActiveInactiveStatusBadge'
import GroupLogo from '$components/GroupLogo'
import DeleteWithConfirmInput from '$components/DeleteWithConfirmInput'
import PreviewScreen from '../PreviewScreen'
import { toast } from 'sonner'
import { useCountryStore } from '$hooks/stores'

const descriptionText = `Setup a mobile, web or IoT application to use CARROsso for Authentication.`

interface IAppUserFormProps {
  defaultValues: TFormValues
  appName: string
  roles?: TAppUserDetail['roles']
  password_reset_link_expired_in?: TAppUserDetail['password_reset_link_expired_in']
}

const AppUserForm = ({
  defaultValues,
  appName,
  roles,
  password_reset_link_expired_in
}: IAppUserFormProps) => {
  const {
    route: { params }
  } = useRouteSummary()

  const { appId, userId } = params

  const [showPreview, setShowPreview] = useState(false)

  const [validPasswordPolicy, setValidPasswordPolicy] = useState(true)

  const handleOnValidatePasswordPolicy = useCallback((value: boolean) => {
    setValidPasswordPolicy(value)
  }, [])

  const methods = useForm<TFormValues>({
    resolver: zodResolver(schema),
    defaultValues,
    criteriaMode: 'all'
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
    getValues
  } = methods

  const { saveUserAsync, savingUser, updateUserAsync, updatingUser } =
    useAppUserService()

  const phoneOptions = useCountryStore(state => state.phoneOptions)

  const handleOnRedirect = useCallback(() => {
    redirect(ROUTE_NAMES.APP_USERS, { params })
  }, [params])

  const handleOnCancel = useCallback(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  const onSubmit = useCallback(
    ({ gender, date_of_birth, phone_code, status, ...rest }: TFormValues) => {
      if (!appId) return

      if (!validPasswordPolicy) {
        toast.error('Your password must follow password policy!')
        return
      }

      const payload: TAppUserPayload = {
        gender: gender.value,
        date_of_birth: format(new Date(date_of_birth), 'dd-MM-yyyy'),
        phone_code: phone_code.value,
        status: userId ? status : undefined,
        ...rest
      }

      if (userId) {
        updateUserAsync(appId, userId, {
          ...payload,
          is_password_updated: !!payload.password
        }).then(handleOnRedirect)
        return
      }

      saveUserAsync(appId, payload).then(() => {
        setShowPreview(true)
      })
    },
    [
      appId,
      userId,
      saveUserAsync,
      updateUserAsync,
      handleOnRedirect,
      validPasswordPolicy
    ]
  )

  const title = (() => {
    if (userId) return `${defaultValues.name}`
    return `Add New User for ${appName}`
  })()

  const isProcessing = savingUser || updatingUser

  if (!phoneOptions.length) return <Loading />

  const user_countries =
    roles
      ?.reduce(
        (r, c) => {
          if (r.some(v => v.id === c.country.id)) return r
          return [...r, c.country]
        },
        [] as Array<TAppUserDetail['roles'][number]['country']>
      )
      .map(v => v.name) ?? []

  const user_groups =
    roles?.reduce(
      (r, c) => {
        if (r.some(v => v.id === c.group.id)) return r
        return [
          ...r,
          {
            ...c.group,
            country_code: c.country.country_code,
            country_id: c.country.id
          }
        ]
      },
      [] as Array<
        TAppUserDetail['roles'][number]['group'] & {
          country_code: string
          country_id: number
        }
      >
    ) ?? []

  if (showPreview) return <PreviewScreen data={getValues()} />

  return (
    <FormProvider {...methods}>
      <form
        className="flex flex-col gap-y-4 flex-1"
        onSubmit={handleSubmit(onSubmit)}
      >
        <header className="flex items-center gap-2 justify-between">
          <p className="flex items-center gap-x-2 text-xl font-semibold text-[--colors-text-light]">
            {title}

            {!!userId && (
              <ActiveInactiveStatusBadge
                status={
                  defaultValues.status === 'active' ? 'active' : 'inactive'
                }
              />
            )}
          </p>

          <article className="flex items-center gap-x-2">
            {userId && (
              <SaveWithShortCutButton
                disabled={!isDirty || isProcessing}
                loading={isProcessing}
              />
            )}

            {!userId && (
              <>
                <Button
                  disabled={isProcessing}
                  styleVariants={{ type: 'outlined', kind: 'neutral' }}
                  onClick={handleOnCancel}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isDirty || isProcessing}
                  type="submit"
                  className="!px-4"
                >
                  {isProcessing && <Spinner />}
                  Add
                </Button>
              </>
            )}
          </article>
        </header>

        <main className="flex-1 flex flex-col gap-y-4">
          {!!userId && (
            <article className="flex flex-col bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
              <article className="flex items-center gap-x-2">
                <article className="flex flex-col gap-y-1">
                  <p className="font-semibold text-[--colors-text-neutral]">
                    {defaultValues.name}'s ROLES, REGIONS & GROUPS
                  </p>
                  <p className="text-[--colors-neutral-50] text-xs">
                    {descriptionText}
                  </p>
                </article>
              </article>

              {!!roles?.length && (
                <article className="grid lg:grid-cols-[40%_60%] gap-4">
                  <article>
                    <article className="flex flex-wrap gap-2">
                      {user_countries.map((v, k) => {
                        return (
                          <span
                            key={k}
                            className="inline-flex items-center bg-[--colors-alphaTertiary-1] text-[--colors-tertiary-default] rounded px-3 py-0.5 text-xs"
                          >
                            {v}
                          </span>
                        )
                      })}
                    </article>
                  </article>

                  <article className="flex flex-col gap-y-8 max-w-[400px]">
                    <article className="flex flex-col gap-y-2 max-h-[500px] overflow-y-auto">
                      <p className="text-sm font-medium text-[--colors-text-disabled]">
                        GROUP
                      </p>

                      {user_groups.map((v, k) => {
                        return (
                          <article
                            key={k}
                            className="flex p-3 items-center gap-x-2 rounded-lg bg-[--colors-alphaArea-disabled] border border-[--colors-neutral-10]"
                          >
                            <article className="rounded-lg grid place-items-center p-1 bg-[--colors-alphaNeutral-1]">
                              <GroupLogo
                                country={{
                                  id: v.country_id,
                                  code: v.country_code
                                }}
                              />
                            </article>

                            <article className="font-medium text-sm text-[--colors-text-light]">
                              {v.name}
                            </article>
                          </article>
                        )
                      })}
                    </article>

                    <article className="flex flex-col gap-y-2 max-h-[500px] overflow-y-auto">
                      <p className="text-sm font-medium text-[--colors-text-disabled]">
                        ROLES
                      </p>

                      {roles.map((v, k) => {
                        return (
                          <article
                            key={k}
                            className="flex items-start gap-x-2 rounded-lg px-3 py-2.5 bg-[--colors-alphaNeutral-0] border border-[--colors-neutral-10]"
                          >
                            <Avatar size={28} />

                            <article className="flex flex-col gap-y-px">
                              <article className="font-medium text-sm text-[--colors-text-light]">
                                {v.name}
                              </article>
                              <article className="flex items-center">
                                <span className="inline-flex items-center rounded bg-[--colors-alphaTertiary-1] text-[--colors-tertiary-default] px-1.5 text-xs">
                                  {v.country.country_code}
                                </span>
                              </article>
                            </article>
                          </article>
                        )
                      })}
                    </article>
                  </article>
                </article>
              )}
            </article>
          )}

          <article className="grid lg:grid-cols-[40%_60%] bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
            <article className="flex flex-col gap-y-1">
              <p className="font-semibold text-[--colors-text-neutral]">
                USER INFO
              </p>
              <p className="text-[--colors-neutral-50] text-xs">
                {descriptionText}
              </p>
            </article>

            <article className="flex flex-col gap-y-4 px-5 max-w-[500px]">
              <BaseText
                control={control}
                name="name"
                label="User Name"
                required
              />

              <BaseText
                control={control}
                name="date_of_birth"
                label="Date of Birth"
                inputProps={{ type: 'date' }}
                required
              />

              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <article className="flex flex-col gap-y-1">
                      <label className="text-sm font-medium">Gender</label>
                      <Select
                        {...field}
                        options={GENDER_OPTIONS}
                        error={!!error?.message}
                      />
                      {error?.message && (
                        <p className="text-xs text-[--colors-danger-default]">
                          {error.message}
                        </p>
                      )}
                    </article>
                  )
                }}
              />

              <BaseText control={control} name="email" label="Email" required />

              <article className="relative">
                <BaseText
                  control={control}
                  name="phone_no"
                  label="Phone Number"
                  inputProps={{ className: 'pl-[100px]' }}
                />

                <article className="absolute bottom-0 left-0 w-[100px]">
                  <Controller
                    name="phone_code"
                    control={control}
                    render={({ field }) => {
                      return (
                        <Select
                          value={field.value}
                          onChange={field.onChange}
                          options={phoneOptions}
                          type="sub"
                        />
                      )
                    }}
                  />
                </article>
              </article>

              <Textarea
                name="address"
                control={control}
                label="Address"
                required
              />
            </article>
          </article>

          <GeneratePassword
            password_reset_link_expired_in={password_reset_link_expired_in}
            validPasswordPolicy={validPasswordPolicy}
            handleOnValidatePasswordPolicy={handleOnValidatePasswordPolicy}
          />

          <AppUserRoleList appName={appName} />

          {userId && (
            <DeleteWithConfirmInput type="user" name={defaultValues.name} />
          )}
        </main>
      </form>
    </FormProvider>
  )
}

export default AppUserForm
