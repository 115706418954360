import { Spinner } from '@genie-fintech/ui/components'

const Loading = () => {
  return (
    <article className="absolute inset-0 grid place-items-center z-10">
      <Spinner size="2em" />
    </article>
  )
}

export default Loading
