export const GroupIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.40913 0.908949C4.83109 0.486992 5.40339 0.249939 6.00012 0.249939H8.9515C9.32209 0.251856 9.68647 0.345293 10.0123 0.521928C10.336 0.697444 10.6117 0.949716 10.8151 1.25657L11.4269 2.1518L11.434 2.16245C11.5019 2.26562 11.5943 2.35041 11.7029 2.40929C11.811 2.46791 11.9319 2.49904 12.0549 2.49994H15.0001C15.5969 2.49994 16.1692 2.73699 16.5911 3.15895C17.0131 3.58091 17.2501 4.1532 17.2501 4.74994V9.24994C17.2501 9.84668 17.0131 10.419 16.5911 10.8409C16.1692 11.2629 15.5969 11.4999 15.0001 11.4999H6.00012C4.76091 11.4999 3.75012 10.4892 3.75012 9.24994V2.49994C3.75012 1.9032 3.98717 1.33091 4.40913 0.908949ZM6.00012 1.74994C5.80121 1.74994 5.61044 1.82896 5.46979 1.96961C5.32914 2.11026 5.25012 2.30103 5.25012 2.49994V9.24994C5.25012 9.66073 5.58934 9.99994 6.00012 9.99994H15.0001C15.199 9.99994 15.3898 9.92092 15.5305 9.78027C15.6711 9.63962 15.7501 9.44885 15.7501 9.24994V4.74994C15.7501 4.55103 15.6711 4.36026 15.5305 4.21961C15.3898 4.07896 15.199 3.99994 15.0001 3.99994H12.0487C11.6782 3.99802 11.3138 3.90458 10.988 3.72795C10.6642 3.55243 10.3886 3.30016 10.1851 2.99331L9.57339 2.09808L9.56624 2.08743C9.4983 1.98426 9.40593 1.89947 9.29733 1.84059C9.1892 1.78197 9.06833 1.75084 8.94535 1.74994H6.00012Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50012 3.24994C1.91434 3.24994 2.25012 3.58573 2.25012 3.99994V12.2499C2.25012 12.6607 2.58934 12.9999 3.00012 12.9999H13.5001C13.9143 12.9999 14.2501 13.3357 14.2501 13.7499C14.2501 14.1642 13.9143 14.4999 13.5001 14.4999H3.00012C1.76091 14.4999 0.750122 13.4892 0.750122 12.2499V3.99994C0.750122 3.58573 1.08591 3.24994 1.50012 3.24994Z"
        fill="currentColor"
      />
    </svg>
  )
}
