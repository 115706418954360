import AppPermissionForm from '$blocks/AppPermission/Form'
import { DEFAULT_FORM_VALUES } from '$blocks/AppPermission/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'

const AppPermissionCreate = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[
          { name: `${appName} Permissions`, path: ROUTE_NAMES.APP_PERMISSIONS },
          { name: `Add New Permission` }
        ]}
      />

      <AppPermissionForm
        appName={appName}
        defaultValues={DEFAULT_FORM_VALUES}
      />
    </>
  )
}

export default AppPermissionCreate
