import { getPublishApp, getUnPublishApp } from '$services/api'
import { useRequest } from 'ahooks'
import { toast } from 'sonner'

export const useAppPublishUnpublishService = () => {
  const {
    run: fetchPublishApp,
    runAsync: fetchPublishAppAsync,
    loading: fetchingPublishApp
  } = useRequest(getPublishApp, {
    manual: true,
    onSuccess: () => {
      toast.success('Successfully Published!')
    }
  })

  const {
    run: fetchUnpublishApp,
    runAsync: fetchUnpublishAppAsync,
    loading: fetchingUnpublishApp
  } = useRequest(getUnPublishApp, {
    manual: true,
    onSuccess: () => {
      toast.success('Successfully Unpublished!')
    }
  })

  return {
    fetchPublishApp,
    fetchPublishAppAsync,
    fetchingPublishApp,

    fetchUnpublishApp,
    fetchUnpublishAppAsync,
    fetchingUnpublishApp
  }
}
