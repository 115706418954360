import {
  routeState,
  summaryState,
  watchRouteState,
  watchSummaryState
} from '$app/router/config'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { RouteContext } from './context'

const useRouterValues = () => {
  const [routeStateValue, setRouteStateState] = useState(routeState)
  const [summaryStateValue, setSummaryStateState] = useState(summaryState)

  useEffect(() => watchRouteState(setRouteStateState), [])
  useEffect(() => watchSummaryState(setSummaryStateState), [])

  return {
    routeState: routeStateValue,
    summaryState: summaryStateValue
  }
}

export const RouterProvider: FC<PropsWithChildren> = ({ children }) => {
  const value = useRouterValues()

  return <RouteContext.Provider value={value}>{children}</RouteContext.Provider>
}
