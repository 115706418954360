import * as SwitchButton from '@radix-ui/react-switch'

interface ISwitchProps {
  checked: boolean
  onChange: (checked: boolean) => void
}

const Switch = ({ checked, onChange }: ISwitchProps) => {
  return (
    <SwitchButton.Root
      checked={checked}
      onCheckedChange={onChange}
      className="w-[42px] h-[25px] bg-[--colors-area-disabled] rounded-full relative shadow-[0_2px_10px] shadow-[--colors-area-high] focus:shadow-[0_0_0_2px] focus:shadow-[--colors-area-high] data-[state=checked]:bg-[--colors-primary-default] outline-none cursor-default"
    >
      <SwitchButton.Thumb className="block w-[21px] h-[21px] bg-[--colors-neutral-40] data-[state=checked]:bg-[--colors-absolute-light] rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform data-[state=checked]:translate-x-[19px]" />
    </SwitchButton.Root>
  )
}

export default Switch
