import { Icon } from '@genie-fintech/ui/icons'
import { capitalize } from 'lodash-es'

interface IEmptyDataProps {
  type: string
  onClick?: VoidFunction
}

const EmptyData = ({ type, onClick }: IEmptyDataProps) => {
  return (
    <article className="flex flex-col justify-center items-center gap-y-4">
      {onClick && (
        <button
          onClick={onClick}
          className="flex flex-col gap-y-4 items-center rounded-lg text-[--colors-text-disabled] hover:text-[--colors-text-light] hover:border-[--colors-neutral-default] duration-200 bg-[--colors-area-high] border border-[--colors-neutral-10] shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1] p-14"
        >
          <Icon namespace="Add" />
          <span className="font-medium">Add New {capitalize(type)}</span>
        </button>
      )}

      <p className="text-xl font-semibold text-[--colors-neutral-70]">
        You don't have any {type} yet.
      </p>

      <p className="text-xs font-medium text-[--colors-neutral-50]">
        No Items have been added to this section
      </p>
    </article>
  )
}

export default EmptyData
