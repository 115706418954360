import { z } from 'zod'

export const schema = z.object({
  country: z.string().trim().min(1, 'Required!'),
  name: z.string().trim().min(1, 'Requried!'),
  phone_no: z.string().trim(),
  phone_code: z.object({
    value: z.string().trim(),
    label: z.string().trim()
  }),
  address: z.string().trim(),
  description: z.string().trim()
})

export type TFormValues = z.infer<typeof schema>

export const DEFAULT_FORM_VALUES: TFormValues = {
  country: '',
  name: '',
  phone_code: { label: '', value: '' },
  phone_no: '',
  address: '',
  description: ''
}
