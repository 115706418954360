import CloseButton from '$components/CloseButton'
import DragAndDropFileUpload from '$components/DragAndDropFileUpload'
import { PER_PAGE } from '$hooks/actions'
import {
  errorMessageResolver,
  postApp,
  TAppListRequest,
  TAppListResponse
} from '$services/api'
import { Button, Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/hook-fields'
import { Icon } from '@genie-fintech/ui/icons'
import { zodResolver } from '@hookform/resolvers/zod'
import * as Dialog from '@radix-ui/react-dialog'
import { useRequest } from 'ahooks'
import { useCallback, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

const schema = z.object({
  name: z.string().trim().min(1, 'Requried!'),
  key: z.string().trim(),
  brand: z.string().trim()
})

type TFormValues = z.infer<typeof schema>

interface ICreateAppProps {
  refreshList: (params: TAppListRequest) => Promise<TAppListResponse>
  onCreateCallbackFn: VoidFunction
}

const AppCreatePopUp = ({
  refreshList,
  onCreateCallbackFn
}: ICreateAppProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const { control, handleSubmit, reset, setValue } = useForm<TFormValues>({
    resolver: zodResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      key: '',
      brand: 'captain'
    }
  })

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const handleCloseDialog = useCallback(() => {
    reset()
    setDialogOpen(false)
  }, [reset])

  const { run: saveApp, loading: savingApp } = useRequest(postApp, {
    manual: true,
    onSuccess: () => {
      toast.success('Successfully Created!')
      refreshList({ page: 1, per_page: PER_PAGE, q: '' }).finally(() => {
        onCreateCallbackFn()
        handleCloseDialog()
      })
    },
    onError: err => {
      toast.error(errorMessageResolver(err))
    }
  })

  const brand = useWatch({ name: 'brand', control })

  const handleCreate = useCallback(
    ({ key, ...payload }: TFormValues) => {
      saveApp({ logo: { key }, ...payload })
    },
    [saveApp]
  )

  const handleCancel = useCallback(() => {
    reset()
  }, [reset])

  const handleOnUploadLogo = useCallback(
    (key: string) => {
      setValue('key', key)
    },
    [setValue]
  )

  const handleOnChangeBrand = useCallback(
    (brand: string) => {
      setValue('brand', brand)
    },
    [setValue]
  )

  return (
    <Dialog.Root open={dialogOpen}>
      <Button onClick={handleOpenDialog}>
        <Icon namespace="Add" />
        <span className="whitespace-nowrap">Create Application</span>
      </Button>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content aria-describedby="" className="DialogContent max-w-lg">
          <Dialog.Title>
            <header className="flex items-center justify-between gap-x-1 p-3 border-b border-[--colors-neutral-10]">
              <p className="font-medium">Create Application</p>

              <CloseButton disabled={savingApp} onClick={handleCloseDialog} />
            </header>
          </Dialog.Title>

          <form className="flex flex-col" onSubmit={handleSubmit(handleCreate)}>
            <article className="flex flex-col px-3 py-2">
              <BaseText
                control={control}
                name="name"
                label="Application Name"
                required
              />
            </article>

            <article className="flex px-3 py-2">
              <DragAndDropFileUpload
                brand={brand}
                onUploadLogo={handleOnUploadLogo}
                onChangeBrand={handleOnChangeBrand}
              />
            </article>

            <article className="flex justify-end items-center gap-x-2 px-3 py-2 bg-[--colors-alphaNeutral-0]">
              <Button
                styleVariants={{ type: 'outlined' }}
                onClick={handleCancel}
                disabled={savingApp}
              >
                Cancel
              </Button>

              <Button type="submit" disabled={savingApp}>
                {savingApp && <Spinner />}
                Create
              </Button>
            </article>
          </form>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AppCreatePopUp
