import Breadcrumb from '$components/Breadcrumb'
import { useAppDetailStore } from '$hooks/stores'
import PageLayout from '$layouts/PageLayout'
import { redirect, ROUTE_NAMES } from '$router/config'
import { Button } from '@genie-fintech/ui/components'
import { TFormValues } from '../Form/constants'
import { CopyButton } from '$components/CopyButon'
import { useCallback, useMemo } from 'react'
import {
  APP_CARRO_LAUNCHPAD_URL,
  APP_GENIE_LAUNCHPAD_URL
} from '$services/environments'
import { Download } from 'lucide-react'
import { Password } from '@genie-fintech/ui/components/fields'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useLeavePageWarning } from '$hooks/actions'
import { APP_BRANDS } from '$app/constants'

const PreviewScreen = ({ data }: { data: TFormValues }) => {
  const {
    route: { params }
  } = useRouteSummary()

  useLeavePageWarning()

  const { name: appName, brand } =
    useAppDetailStore(state => state.appDetail) ?? {}

  const LAUNCHPAD_URL =
    brand === APP_BRANDS[2].name
      ? APP_GENIE_LAUNCHPAD_URL
      : APP_CARRO_LAUNCHPAD_URL

  const copiedValue = useMemo(() => {
    return `<p><b>Username</b></p><p>${data.name}</p><br/><p><b>Email</b></p><p>${data.email}</p><br/><p><b>Carro SSO Login Link</b></p><p>${LAUNCHPAD_URL}</p><br/><p><b>Password</b></p><p>${data.password}</p>`
  }, [data, LAUNCHPAD_URL])

  const handleOnDownloadCSV = useCallback(() => {
    const csvHeaders = ['Email', 'Password', 'Carro SSO Login Link'].join(',')
    const csvRow = [data.email, data.password, LAUNCHPAD_URL].join(',')
    const csvContent = [csvHeaders, csvRow].join('\r\n')
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

    const link = document.createElement('a')
    link.setAttribute('href', URL.createObjectURL(blob))
    link.setAttribute('download', `${data.name}.csv`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    redirect(ROUTE_NAMES.APP_USERS, { params })
  }, [data, params, LAUNCHPAD_URL])

  return (
    <article className="fixed inset-0 bg-[--colors-area-medium] z-[9999]">
      <PageLayout removeLink>
        <article className="flex flex-col gap-y-4 flex-1">
          <Breadcrumb
            mainPage={ROUTE_NAMES.APPS}
            data={[
              { name: `${appName} Users`, path: ROUTE_NAMES.APP_USERS },
              { name: `Add New User` }
            ]}
            disabled
            renderAsChild
          />

          <header className="flex items-center gap-2 justify-between">
            <p className="flex items-center gap-x-2 text-xl font-semibold text-[--colors-text-light]">
              Retrieve Password and Information
            </p>

            <article className="flex items-center gap-x-2">
              <CopyButton
                value={copiedValue}
                size={16}
                classNames={{
                  container:
                    'flex-1 flex gap-x-2 items-center rounded border border-[--colors-neutral-20] bg-[--colors-area-high] py-1.5 px-3'
                }}
              >
                <span className="text-sm text-inherit">Copy to Clipboard</span>
              </CopyButton>
              <Button
                styleVariants={{ kind: 'neutral' }}
                className="!px-4"
                onClick={handleOnDownloadCSV}
              >
                Download .csv file
                <Download size={16} />
              </Button>
            </article>
          </header>

          <main className="flex flex-col bg-[--colors-area-high] p-5 rounded-lg gap-4 border border-[--colors-neutral-10] shadow-[0px_2px_4px_2px] shadow-[--colors-alphaNeutral-1]">
            <article>
              <p className="font-semibold">DOWNDLOAD .CSV FILE TO PROCEED</p>
              <p className="text-xs text-[--colors-neutral-50]">
                Setup a mobile, web or IoT application to use CARROsso for
                Authentication.
              </p>
            </article>

            <article>
              <p className="text-xs font-medium text-[--colors-neutral-60]">
                Username
              </p>
              <p className="text-sm font-semibold text-[--colors-text-light]">
                {data.name}
              </p>
            </article>

            <article>
              <p className="text-xs font-medium text-[--colors-neutral-60]">
                Email Address
              </p>
              <p className="text-sm font-semibold text-[--colors-text-light]">
                {data.email}
              </p>
            </article>

            <article>
              <p className="text-xs font-medium text-[--colors-neutral-60]">
                CARROsso Login Link
              </p>
              <p className="text-sm font-semibold text-[--colors-text-light]">
                {APP_GENIE_LAUNCHPAD_URL}
              </p>
            </article>

            <article>
              <p className="font-semibold">PASSWORD</p>
              <p className="text-xs text-[--colors-neutral-50]">
                Setup a mobile, web or IoT application to use CARROsso for
                Authentication.
              </p>
            </article>

            <article className="inline-flex">
              <Password
                label="Password"
                disabled
                fullWidth={false}
                inputProps={{ defaultValue: data.password, disabled: true }}
              />
            </article>
          </main>
        </article>
      </PageLayout>
    </article>
  )
}

export default PreviewScreen
