import { generatePwd } from '$app/utils'
import AppUserForm from '$blocks/AppUser/Form'
import { DEFAULT_FORM_VALUES } from '$blocks/AppUser/Form/constants'
import Breadcrumb from '$components/Breadcrumb'
import { useAppDetailStore } from '$hooks/stores'
import { ROUTE_NAMES } from '$router/config'

const AppUserCreate = () => {
  const appName = useAppDetailStore(state => state.appDetail?.name) ?? ''

  return (
    <>
      <Breadcrumb
        mainPage={ROUTE_NAMES.APPS}
        data={[
          { name: `${appName} Users`, path: ROUTE_NAMES.APP_USERS },
          { name: `Add New User` }
        ]}
      />

      <AppUserForm
        appName={appName}
        defaultValues={{ ...DEFAULT_FORM_VALUES, password: generatePwd() }}
      />
    </>
  )
}

export default AppUserCreate
