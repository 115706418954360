export const PASSWORD_POLICY_CONFIG = [
  {
    id: 1,
    label: 'No more than 2 identical characters in a row'
  },
  {
    id: 2,
    label: 'Special characters (!@#$%^&*)'
  },
  {
    id: 3,
    label: 'Lower case (a-z), upper case (A-Z) and numbers (0-9)'
  },
  {
    id: 4,
    label: 'Must have ### characters in length'
  },
  {
    id: 5,
    label: 'Non-empty password required'
  }
]

export const MIN_LENGTH_ID = 4

export const TWO_IDENTICAL_ROW_REGEX = /^(?!.*(.)\1{2,}).*$/

export const SPECIAL_LETTER_REGEX = /^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/

export const LOWERCASE_UPPERCASE_NUMBER_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/
